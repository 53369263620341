import React, { Component, useState } from "react";
import styles from "./Properties.module.css";
import textStyles from "../Properties/Text.module.css";
import styled from "styled-components";
import Popup from "../Dialog/Popup";
import CodeEditor from "../CodeEditor/CodeEditor";

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  background-color: #1f252f;
  border-radius: 4px;
  padding: 5px;
  box-shadow: 0px 3px 3px -20px rgba(0, 0, 0, 0.2),
    0px 5px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
`;

const Title = styled.div`
  font-size: 1.1em;
  background-color: #282f3c;
  padding: 0.3em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #161a21;
`;

const Row = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: space-evenly;
`;

const ScriptRow = styled.div`
  display: flex;
  padding: 5px;

  flex-direction: column;
  width: 100%;
`;
const Select = styled.select`
  background-color: #181d25;
  color: #fff;
  padding: 4px;
  border: none;
  margin: 4px;
  border-radius: 4px;
  width: 100%;
`;
const Delete = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #282f3c;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  margin: 1px;
  :hover {
    background-color: red;
  }
`;

const Button = styled.div`
  background-color: ${(props) => (props.red ? "red" : "#353a47")};
  color: ${(props) => (props.green ? "black" : "")};
  border-radius: 4px;
  padding: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-left: 0.5em;
  :hover {
    color: #fff;
    background-color: ${(props) => (props.green ? "#05ff97" : "#db0a41")};
  }
`;

export default function TimelineProperties({ timeline, timelines, scene }) {
  const [showDelete, setShowDelete] = useState(false);

  return (
    <div className={styles.Properties}>
      <Group>
        <Title>Timeline - {timeline.name}</Title>

        <div className={textStyles.Property}>
          <Button red onClick={() => setShowDelete(true)}>
            DELETE TIMELINE
          </Button>
        </div>

        <div className={textStyles.Property}>
          <label className={textStyles.CheckboxContainer}>
            Loop
            <input
              type="checkbox"
              checked={timeline.loop ? true : false}
              onChange={(e) => {
                timeline.loop = e.currentTarget.checked;
              }}
            />
            <span className={textStyles.Checkmark} />
          </label>
        </div>
        <div className={textStyles.Property}>
          <label className={textStyles.CheckboxContainer}>
            Play on engine load
            <input
              type="checkbox"
              checked={timeline.play_on_engine_load ? true : false}
              onChange={(e) => {
                timeline.play_on_engine_load = e.currentTarget.checked;
              }}
            />
            <span className={textStyles.Checkmark} />
          </label>
        </div>

        {timeline.name === "OUT" && (
          <div className={textStyles.Property}>
            <label className={textStyles.CheckboxContainer}>
              Reverse IN
              <input
                type="checkbox"
                checked={timeline.reverse_in ? true : false}
                onChange={(e) => {
                  timeline.reverse_in = e.currentTarget.checked;
                }}
              />
              <span className={textStyles.Checkmark} />
            </label>
          </div>
        )}

        <input
          value={timeline.fps}
          onChange={(e) => {
            timeline.fps = e.currentTarget.value;
          }}
        />
      </Group>
      <Group>
        <Title>Timeline Triggers</Title>

        <Row>
          <div>Frame</div>
          <div>Action</div>
          <div>Timeline</div>
          <div></div>
        </Row>
        {timeline?.tracks
          ?.find((t) => t.object?.name === "Events")
          ?.animations?.find((anim) => anim.property === "TIMELINE")
          ?.keyframes?.map((kf, index) => {
            return (
              <Row>
                <input
                  style={{ width: "100%" }}
                  value={kf.frame}
                  onChange={(e) => {
                    kf.frame = parseInt(e.currentTarget.value) || 0;
                  }}
                />
                <Select
                  placeholder="Selected Action"
                  onChange={(event) => {
                    kf.action = event.target.value;
                  }}
                  value={kf.action}
                >
                  <option value="PLAY">PLAY</option>
                  <option value="STOP">STOP</option>
                </Select>
                <Select
                  placeholder="Selected TL"
                  onChange={(event) => {
                    kf.name = event.target.value;
                  }}
                  value={kf.name}
                >
                  {timelines?.map((tl) => {
                    return <option value={tl.name}>{tl.name}</option>;
                  })}
                </Select>
                <Delete
                  onClick={() => {
                    let anim = timeline?.tracks
                      ?.find((t) => t.object?.name === "Events")
                      ?.animations?.find(
                        (anim) => anim.property === "TIMELINE"
                      );

                    anim.keyframes.splice(index, 1);
                  }}
                >
                  DEL
                </Delete>
              </Row>
            );
          })}
        <Row>
          <Delete
            onClick={() => {
              let tl = timeline;

              let track = tl.tracks.find((t) => t.object?.name === "Events");
              let anim = track?.animations?.find(
                (anim) => anim.property === "TIMELINE"
              );
              if (!anim) {
                anim = { property: "TIMELINE" };
                track.animations.push(anim);
              }

              anim.keyframes = anim.keyframes || [];
              anim.keyframes.push({ frame: 1, name: "", action: "PLAY" });
            }}
          >
            + New Trigger
          </Delete>
        </Row>
      </Group>
      <Group>
        <Title>Script Triggers</Title>

        {timeline?.tracks
          ?.find((t) => t.object?.name === "Events")
          ?.animations?.find((anim) => anim.property === "SCRIPT")
          ?.keyframes?.map((kf, index) => {
            return (
              <ScriptRow>
                <Row>
                  <div>Frame</div>
                  <input
                    style={{ width: "100%" }}
                    value={kf.frame}
                    onChange={(e) => {
                      kf.frame = parseInt(e.currentTarget.value) || 0;
                    }}
                  />
                </Row>
                <Row>
                  <Delete
                    onClick={() => {
                      let anim = timeline.tracks
                        .find((t) => t.object?.name === "Events")
                        .animations?.find((anim) => anim.property === "SCRIPT");

                      anim.keyframes.splice(index, 1);
                    }}
                  >
                    DEL
                  </Delete>
                </Row>
                <div style={{ width: "100%" }}>
                  <CodeEditor
                    item={kf}
                    default_code="function trigger(scene, delta) {


                    }"
                  />
                </div>
              </ScriptRow>
            );
          })}
        <Row>
          <Delete
            onClick={() => {
              let tl = timeline;

              let track = tl.tracks.find((t) => t.object?.name === "Events");
              let anim = track?.animations?.find(
                (anim) => anim.property === "SCRIPT"
              );
              if (!anim) {
                anim = { property: "SCRIPT" };
                track.animations.push(anim);
              }

              anim.keyframes = anim.keyframes || [];
              anim.keyframes.push({ frame: 1, name: "", action: "PLAY" });
            }}
          >
            + New Script Trigger
          </Delete>
        </Row>
      </Group>
      <Group>
        <Title>Conditional Animation</Title>
        return true or false depending if you want the animation to run.
        <ScriptRow>
          <div style={{ width: "100%" }}>
            <CodeEditor
              item={timeline?.conditional_script}
              default_code="function runAnimation(scene) {
                      return true;
                    }"
            />
          </div>
        </ScriptRow>
      </Group>
      {showDelete && (
        <Popup
          title="Delete Timeline"
          message="Are you sure you want to delete this timeline and all of its animations?"
          onSave={() => {
            scene.timelines = timelines.filter((t) => t.name !== timeline.name);
            scene.activeTimeline = scene.timelines[0];

            setShowDelete(false);
          }}
          onCancel={() => {
            setShowDelete(false);
          }}
        />
      )}
    </div>
  );
}
