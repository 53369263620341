import * as TWEEN from "@tweenjs/tween.js";
import * as BezierEasing from "bezier-easing";
import _ from "lodash";
import uuid from "uuid/v4";
import {
  setProperty as SET_PROPERTY,
  trigger as TRIGGER,
  timeline as TIMELINE,
  script as SCRIPT,
} from "./PropertyUtils";
const PIXI = window.PIXI;
class Timeline {
  constructor(name, scene) {
    this.name = name;
    this.playingState = 0;
    this.tracks = [];
    this.currentTime = 0;
    this.frameNumber = -1;
    this.delta = 0;
    this.duration = 25;
    this.loop = false;
    this.animate_background = true;
    this.fps = 50;
    this.scene = scene;
    this.id = uuid();
    this.conditional_script = {};
    this.seeking = false;
    this.reversed = false;
    this.reverse_in = false;
    this.play_on_engine_load = false;
    // if (name === "T3") {
    //   this.fps = 60;
    // }

    this.addTrack({
      name: "Events",
    });
  }

  getKeyframesByItem(item) {
    try {
      let track = this.tracks.find((track) => track.object === item);
      if (!track || track.animations.length === 0) {
        return [];
      }
      return track.animations[0].keyframes;
    } catch (err) {
      return [];
    }
  }

  getAnimationsByItem(item) {
    let track = this.tracks.find((track) => track.object === item);
    if (!track) {
      return [];
    }
    return track.animations;
  }

  togglePlay() {
    this.reversed = false;
    if (this.playingState === 0 && this.playAnimation(this.scene.scene)) {
      this.playingState = 1;
    } else {
      this.playingState = 0;
    }
  }

  play() {
    this.reversed = false;
    if (this.playAnimation(this.scene.scene)) {
      this.frameNumber = -1;
      this.playingState = 1;
    }
  }

  reverse() {
    if (this.playAnimation(this.scene.scene)) {
      this.frameNumber = this.duration + 1;
      this.reversed = true;
      this.playingState = 1;
    }
  }

  pause() {
    this.playingState = 0;
  }

  stop() {
    this.playingState = 0;
    this.currentTime = 0;
    this.frameNumber = -1;
  }

  addTrack(item) {
    let t = this.tracks.find((t) => {
      return t.object === item;
    });
    if (t) {
      return t;
    }
    let track = {
      object: item,
      animations: [],
    };
    this.tracks.push(track);
    return track;
  }

  removeTrack(item) {
    if (this.tracks.length > 0) {
      let track = this.tracks.findIndex((t) => {
        return t.object.uuid === item.uuid;
      });
      if (track > -1) {
        this.tracks.splice(track, 1);
      }
    }
  }

  addAnimation(track, property) {
    let anim = {
      property: property,
      keyframes: [],
    };
    track.animations.push(anim);
    return anim;
  }

  addKeyframe({ frame, property, value, item }) {
    let track = this.tracks.find((track) => track.object === item);
    if (!track) {
      track = this.addTrack(item);
    }
    let animation = track.animations.find((anim) => anim.property === property);
    if (!animation) {
      animation = this.addAnimation(track, property);
    }

    let old_kf = animation.keyframes.find((kf) => {
      return kf.frame == frame;
    });

    animation.keyframes = animation.keyframes.filter((kf) => {
      return kf.frame !== frame;
    });

    if (property === "TRIGGER") {
      animation.keyframes.push({ frame, value: value });
    } else if (property === "visible") {
      animation.keyframes.push({
        frame,
        value: value == "true" ? true : false,
        curve: old_kf?.curve || [0, 0, 1, 1],
        type: old_kf?.type || "linear",
      });
    } else if (property === "colour") {
      animation.keyframes.push({
        frame,
        value: value,
        curve: old_kf?.curve || [0, 0, 1, 1],
        type: old_kf?.type || "linear",
      });
    } else if (property === "text") {
      animation.keyframes.push({
        frame,
        value: value,
        curve: old_kf?.curve || [0, 0, 1, 1],
        type: old_kf?.type || "none",
      });
    } else {
      animation.keyframes.push({
        frame,
        value: parseFloat(value),
        curve: old_kf?.curve || [0, 0, 1, 1],
        type: old_kf?.type || "linear",
      });
    }

    animation.keyframes.sort((a, b) => a.frame - b.frame);

    // if (this.duration < animation.keyframes.slice().reverse()[0].frame - 1) {
    //   this.duration = animation.keyframes.slice().reverse()[0].frame - 1;
    // }
  }

  removeKeyframe(animation, keyframe) {
    let kf = animation.keyframes.find((key) => key.frame === keyframe);
    if (kf) {
      animation.keyframes.splice(animation.keyframes.indexOf(kf), 1);
      if (animation.keyframes.length === 0) {
        let track = this.tracks.find((track) => {
          return track.animations.indexOf(animation) > -1;
        });
        track.animations.splice(track.animations.indexOf(animation), 1);
      }
    }
  }

  moveKeyframe(to, from, animation) {
    let kf = animation.keyframes.find((key) => key.frame === from);
    kf.frame = to;
    animation.keyframes.sort((a, b) => a.frame - b.frame);
    // if (this.duration < animation.keyframes.slice().reverse()[0].frame - 1) {
    //   this.duration = animation.keyframes.slice().reverse()[0].frame - 1;
    // }
  }

  getTween(keyframes, frame) {
    let firstKf, lastKf;
    // if (this.reversed) {
    //   firstKf = keyframes
    //     .slice()
    //     .reverse()
    //     .find((keyframe) => {
    //       return keyframe.frame <= frame;
    //     });

    //   lastKf =
    //     keyframes.find((keyframe) => {
    //       return keyframe.frame > frame;
    //     }) || null;

    //   if (lastKf && firstKf) {
    //     let new_firstKf = { ...firstKf };
    //     let new_lastKf = { ...lastKf };

    //     new_firstKf.time = firstKf.frame * (1000 / this.fps);
    //     new_lastKf.time = lastKf.frame * (1000 / this.fps);
    //     // debugger;
    //     // new_firstKf.curve = lastKf.curve;
    //     // new_lastKf.curve = firstKf.curve;
    //     new_firstKf.value = lastKf.value;
    //     new_lastKf.value = firstKf.value;
    //     new_firstKf.frame = lastKf.frame;
    //     new_lastKf.frame = firstKf.frame;
    //     let obj = {
    //       first: new_firstKf,
    //       last: new_lastKf,
    //       duration:
    //         new_lastKf.frame * (1000 / this.fps) -
    //         (new_firstKf.frame + 1) * (1000 / this.fps),
    //     };
    //     debugger;
    //     return obj;
    //   }
    // } else {
    firstKf = keyframes
      .slice()
      .reverse()
      .find((keyframe) => {
        return keyframe.frame <= frame;
      });

    lastKf =
      keyframes.find((keyframe) => {
        return keyframe.frame > frame;
      }) || null;

    if (lastKf && firstKf) {
      firstKf.time = firstKf.frame * (1000 / this.fps);
      lastKf.time = lastKf.frame * (1000 / this.fps);

      return {
        first: firstKf,
        last: lastKf,
        duration:
          lastKf.frame * (1000 / this.fps) -
          (firstKf.frame + 1) * (1000 / this.fps),
      };
    }
    // }

    return null;
  }

  ease({ startValue = 0, endValue = 0, durationMs = 1, onStep }) {
    // console.log({ startValue: startValue, endValue: endValue });
    // work out roughly how many steps we want
    const stepCount = durationMs; // how many steps will we take
    const stepSize = (endValue - startValue) / stepCount; // how big will each step be
    // console.log(stepSize);
    return stepSize;
  }

  seek(time) {
    this.seeking = true;

    let f = 0;
    let seek_promises = [];
    while (f <= time) {
      f++;
      this.frameNumber = f;
      this.currentTime = this.frameNumber * ((1 / this.fps) * 1000);
      if (this.reversed) {
        this.stepReversed(0, time, seek_promises);
      } else {
        this.step(0, time, seek_promises);
      }
    }

    this.seeking = false;
    return Promise.all(seek_promises);
  }

  update(delta) {
    if (this.playingState === 1) {
      if (this.reversed) {
        this.stepReversed(delta);
      } else {
        this.step(delta);
      }
    } else {
      this.step(0);
    }
  }

  restart() {
    this.reversed = false;
    this.currentTime = -1;
    this.playingState = 0;
    this.frameNumber = -1;
  }

  executeCode(item, delta, scene) {
    if (item.codeRunner) {
      try {
        item.codeRunner.loaded(item, delta, scene);
      } catch (e) {
        console.error(e);
      }
    }
    if (item.autofollowCode) {
      try {
        item.autofollowCode.update(item, delta, scene);
      } catch (e) {
        console.error(e);
      }
    }
    if (item.children) {
      item.children.map((child) => {
        this.executeCode(child, delta, scene, this);
      });
    }
  }

  playAnimation() {
    if (this.conditional_script?.codeRunner) {
      try {
        return this.conditional_script?.codeRunner?.runAnimation(this.scene);
      } catch (e) {
        console.error(e);
      }
    } else if (this.conditional_script?.code) {
      let func = new Function(
        "hyperAPI",
        "scene",
        `
          let window = '';

          function runAnimation() {

          }
   
           ${this.conditional_script.code}
            return {
              runAnimation: runAnimation,
            };
          `
      )(window.hyperAPI, this.scene);
      this.conditional_script.codeRunner = func;
      return this.conditional_script?.codeRunner?.runAnimation(this.scene);
    }
    return true;
  }

  step(delta, target, seek_promises = []) {
    // console.log("TIMELINE", this.name, this.frameNumber);
    if (this.playingState == 1) {
      this.currentTime += delta * 1000;
    }
    this.delta += delta * 1000;
    //console.log('delta', this.delta)
    let currentTimeFrame = parseInt(this.currentTime / ((1 / this.fps) * 1000));
    //  console.log('currentTime', this.currentTime)
    //  console.log('currentTimeFrame', currentTimeFrame)
    //  console.log('frame', this.frameNumber)
    let missed = 0;
    while (
      currentTimeFrame > this.frameNumber &&
      this.prevFrame != currentTimeFrame &&
      !this.seeking &&
      currentTimeFrame <= this.duration
    ) {
      // if (missed > 0) {
      //   console.log("missed frame");
      // }
      this.frameNumber += 1;
      this.render(delta, target, seek_promises);
      this.delta = 0;
      missed++;
    }
    if (
      currentTimeFrame >= this.duration &&
      this.playingState === 1 &&
      !this.loop
    ) {
      while (this.frameNumber < this.duration) {
        this.frameNumber += 1;
        this.delta = 0;

        this.render(delta, target, seek_promises);
      }
    }
    if (this.seeking && this.frameNumber !== this.prevFrame) {
      this.render(delta, target, seek_promises);
    }
    if (
      this.frameNumber >= this.duration ||
      currentTimeFrame >= this.duration
    ) {
      if (this.loop) {
        this.frameNumber = 1;
        this.currentTime = 0;
      } else {
        if (this.name === "OUT") {
          this.scene.scene.renderable = false;
          this.scene.timelines.forEach((tl) => {
            tl.stop();
          });
          // console.log("renderable false");
        }
        this.restart();
      }
    }
  }
  stepReversed(delta, target, seek_promises = []) {
    // console.log("TIMELINE", this.name, this.frameNumber);
    if (this.playingState == 1) {
      this.currentTime += delta * 1000;
    }
    this.delta += delta * 1000;
    //console.log('delta', this.delta)
    let currentTimeFrame =
      this.duration - parseInt(this.currentTime / ((1 / this.fps) * 1000));
    //  console.log('currentTime', this.currentTime)
    //   console.log("currentTimeFrame", currentTimeFrame);
    //  console.log("frame", this.frameNumber);

    let missed = 0;
    while (
      currentTimeFrame <= this.frameNumber &&
      this.prevFrame != currentTimeFrame &&
      !this.seeking &&
      currentTimeFrame >= 0
    ) {
      // if (missed > 0) {
      //   console.log("missed frame");
      // }
      this.frameNumber -= 1;
      this.render(delta, target, seek_promises);
      this.delta = 0;
      missed++;
    }

    if (this.seeking && this.frameNumber !== this.prevFrame) {
      this.render(delta, target, seek_promises);
    }
    if (this.frameNumber < 0 || currentTimeFrame < 0) {
      if (this.loop) {
        this.frameNumber = this.duration;
        this.currentTime = 0;
      } else {
        if (this.name === "OUT" || (this.name === "IN" && this.reversed)) {
          this.scene.scene.renderable = false;
          this.scene.timelines.forEach((tl) => {
            tl.stop();
          });
          // console.log("renderable false");
        }
        this.restart();
      }
    }
  }
  async render(delta, target, seek_promises) {
    //let frames = parseInt(this.currentTime / 1000 / (1 / 25));
    let frames = this.frameNumber;
    this.prevFrame = frames;

    // window.sequence.gotoAndStop(frames);
    // if (this.name === "OUT") {
    //   debugger;
    // }

    if (this.loop && frames > this.duration) {
      this.frameNumber = 1;
      frames = 1;
    }

    if (this.frameNumber < this.duration && this.frameNumber >= 1) {
      this.scene.scene.renderable = true;
      this.scene.scene.visible = true;
      // console.log("renderable true");
    }

    if (this.name === "IN" && (frames === 1 || frames === 2)) {
      this.executeCode(this.scene.scene, 0, this.scene, this);
    }
    if (
      this.name === "IN" &&
      frames === 1 &&
      this.scene.background_scene &&
      this.animate_background
    ) {
      TIMELINE({ name: "IN" }, this.scene.background_scene, "PLAY", true);
    }
    if (
      this.name === "OUT" &&
      frames === 1 &&
      this.scene.background_scene &&
      this.animate_background
    ) {
      TIMELINE({ name: "OUT" }, this.scene.background_scene, "PLAY", true);
    }
    if (this.name === "UPDATE-IN" && frames === 1) {
      let item = this.scene.getItemByName(
        "UPDATE_SCRIPT",
        this.scene.scene.children
      );
      if (item && item.codeRunner) {
        try {
          item.codeRunner.onUpdateAnim(item, delta, this.scene, this);
        } catch (e) {
          console.error(e);
        }
      }
    }

    // if (frames === 0) {
    //   // item.object.position.x = animation.keyframes[0].value;
    //   return;
    // }
    // console.log(frames);
    for (let y = 0; y < this.tracks?.length; y++) {
      let item = this.tracks[y];
      if (item.object && item.object.codeRunner) {
        try {
          item.object.codeRunner.onUpdateAnim(
            item.object,
            delta,
            this.scene,
            this
          );
        } catch (e) {
          console.error(e);
        }
      }

      for (let x = 0; x < item.animations?.length; x++) {
        let animation = item.animations[x];
        if (item.object.type === "AUDIO") {
          if (item?.object?.audio_player) {
            let kf = animation.keyframes.find((item) => item.frame < frames);

            if (kf) {
              if (item.object.audio_player.paused && kf.value === "play") {
                try {
                  if (window.SingularOverlayControlAudioHandler) {
                    var playCmd = new Object();
                    playCmd.id = item.object.uuid;
                    playCmd.command = "play";
                    console.log(playCmd);
                    window.SingularOverlayControlAudioHandler(playCmd);
                  }
                } catch (err) {
                  console.log(err);
                }
                try {
                  let resp = item.object.audio_player.play();
                  if (resp !== undefined) {
                    resp
                      .then((_) => {
                        // autoplay starts!
                      })
                      .catch((error) => {
                        //show error
                        console.log(error);
                      });
                  }
                } catch (err) {
                  console.log(err);
                }
              } else if (kf.value === "stop") {
                try {
                  if (window.SingularOverlayControlAudioHandler) {
                    var pauseCmd = new Object();
                    pauseCmd.id = item.object.uuid;
                    pauseCmd.command = "pause";
                    console.log(pauseCmd);
                    window.SingularOverlayControlAudioHandler(pauseCmd);

                    var seekCmd = new Object();
                    seekCmd.id = item.object.uuid;
                    seekCmd.command = "seek";
                    seekCmd.time = 0;
                    console.log(seekCmd);
                    window.SingularOverlayControlAudioHandler(seekCmd);
                  }
                } catch (err) {
                  console.log(err);
                }
                try {
                  item.object.audio_player.pause();
                  item.object.audio_player.currentTime = 0;
                } catch (err) {
                  console.log(err);
                }
              }
            }
          }
        } else if (item.object.type === "VIDEO") {
          if (this.seeking === false && this.playingState === 1) {
            if (
              item.object.texture.baseTexture.resource.source.paused === true
            ) {
              let kf = animation.keyframes.find(
                (item) => item.value === "play" && item.frame < frames
              );
              if (kf) {
                let time = (frames - kf.frame) / this.fps;
                item.object.texture.baseTexture.resource.source.currentTime =
                  time;
                seek_promises.push(
                  new Promise((resolve) => {
                    item.object.texture.baseTexture.resource.source.onseeked = (
                      event
                    ) => {
                      item.object.texture.baseTexture.update();
                      item.object.visible = true;
                      resolve();
                    };
                  })
                );
              }
            }
          } else if (this.seeking && target === frames) {
            item.object.texture.baseTexture.resource.source.pause();
            let kf = animation.keyframes.find(
              (item) => item.value === "play" && item.frame <= frames
            );
            if (kf) {
              let time = (frames - kf.frame) / this.fps;
              if (item.object.name === "VIDEO_OUT") {
                console.log(time);
              }
              item.object.texture.baseTexture.resource.source.currentTime =
                time;

              seek_promises.push(
                new Promise((resolve) => {
                  item.object.texture.baseTexture.resource.source.onseeked = (
                    event
                  ) => {
                    item.object.texture.baseTexture.update();
                    item.object.visible = true;
                    resolve();
                  };
                })
              );
            }
          }
        }

        if (
          animation.keyframes.find((kf) => kf.frame === frames) &&
          animation.property === "TRIGGER"
        ) {
          if (item.object.type === "VIDEO") {
            if (this.seeking === false) {
              TRIGGER(
                item.object,
                animation.keyframes.find((kf) => kf.frame === frames).value
              );
            }
          } else {
            TRIGGER(
              item.object,
              animation.keyframes.find((kf) => kf.frame === frames).value
            );
          }
        } else if (
          animation.keyframes.find((kf) => kf.frame === frames) &&
          animation.property === "TIMELINE"
        ) {
          let kf = animation.keyframes.find((kf) => kf.frame === frames);

          TIMELINE(kf, this.scene.name, kf.action);
        } else if (
          animation.keyframes.find((kf) => kf.frame === frames) &&
          animation.property === "SCRIPT"
        ) {
          let kf = animation.keyframes.find((kf) => kf.frame === frames);

          SCRIPT({ kf, scene: this.scene, delta });
        } else if (
          animation.keyframes.find((kf) => kf.frame === frames) &&
          animation.property === "visible"
        ) {
          SET_PROPERTY(
            item.object,
            animation.property,
            animation.keyframes.find((kf) => kf.frame === frames).value
          );
        } else if (animation.property === "colour") {
          let tween = this.getTween(animation.keyframes, frames);

          if (tween?.first?.inherit && frames === tween?.first?.frame) {
            if (
              animation.property === "alpha" &&
              item.object.type === "GROUP" &&
              item.object.filters &&
              item.object.filters?.length > 0
            ) {
              tween.first.value = item.object.filters?.find(
                (f) => f.name === "ALPHA"
              )?.alpha;
            } else {
              tween.first.value = _.get(item.object, animation.property);
            }
          }
          if (tween?.last?.inherit) {
            if (
              animation.property === "alpha" &&
              item.object.type === "GROUP" &&
              item.object.filters &&
              item.object.filters?.length > 0
            ) {
              tween.last.value = item.object.filters?.find(
                (f) => f.name === "ALPHA"
              )?.alpha;
            } else {
              tween.last.value = _.get(item.object, animation.property);
            }
          }
          if (tween && tween.last.type !== "none") {
            let elapsed =
              (this.currentTime - tween.first.time) / tween.duration;
            if (this.reversed) {
              elapsed =
                (this.duration * ((1 / this.fps) * 1000) -
                  this.currentTime -
                  tween.first.time) /
                tween.duration;
            }
            elapsed = tween.duration === 0 || elapsed > 1 ? 1 : elapsed;
            if (elapsed > 0) {
              let value = TWEEN.Easing.Linear.None(elapsed);
              let curve;
              if (tween.last.curve) {
                try {
                  curve = BezierEasing(...tween.last.curve);
                  value = curve(elapsed);
                } catch (err) {}
              }
              let colour_value = this.lerpColor(
                tween.first.value,
                tween.last.value,
                value
              );
              SET_PROPERTY(item.object, animation.property, colour_value);
            }
          } else if (animation.keyframes.find((kf) => kf.frame === frames)) {
            SET_PROPERTY(
              item.object,
              animation.property,
              animation.keyframes?.find((kf) => kf.frame === frames)?.value
            );
          }
        } else {
          let tween = this.getTween(animation.keyframes, frames);
          //console.log(tween);
          if (tween?.first?.inherit && frames === tween?.first?.frame) {
            if (
              animation.property === "alpha" &&
              item.object.type === "GROUP" &&
              item.object.filters &&
              item.object.filters?.length > 0
            ) {
              tween.first.value = item.object.filters.find(
                (f) => f.name === "ALPHA"
              )?.alpha;
            } else {
              tween.first.value = _.get(item.object, animation.property);
            }
          }
          if (tween?.last?.inherit) {
            if (
              animation.property === "alpha" &&
              item.object.type === "GROUP" &&
              item.object.filters &&
              item.object.filters?.length > 0
            ) {
              tween.last.value = item.object.filters?.find(
                (f) => f.name === "ALPHA"
              )?.alpha;
            } else {
              tween.last.value = _.get(item.object, animation.property);
            }
          }
          if (tween && tween.last.type !== "none") {
            let elapsed =
              (this.currentTime - tween.first.time) / tween.duration;
            if (this.reversed) {
              elapsed =
                (this.duration * ((1 / this.fps) * 1000) -
                  this.currentTime -
                  tween.first.time) /
                tween.duration;
            }
            elapsed = tween.duration === 0 || elapsed > 1 ? 1 : elapsed;

            let value = TWEEN.Easing.Linear.None(elapsed);

            let curve;
            if (tween.last.curve) {
              try {
                curve = BezierEasing(...tween.last.curve);
                value = curve(elapsed);
              } catch (err) {}
              //console.log(
              //  tween.duration,
              //  frames + 1,
              //  tween.first.frame,
              //  elapsed,
              //  value
              //);
            }

            // switch (tween.last.type) {
            //   case "ease-in":
            //     value = TWEEN.Easing.Quadratic.In(elapsed);
            //     break;
            //   case "ease-out":
            //     value = TWEEN.Easing.Quadratic.Out(elapsed);
            //     break;
            //   case "ease-in-out":
            //     value = TWEEN.Easing.Quadratic.InOut(elapsed);
            //     break;
            // }
            // let value = TWEEN.Easing.Elastic.InOut(elapsed);
            // if (animation.property === "position.y") {
            //   console.log(
            //     tween.first.value,
            //     tween.last.value,
            //     tween.last.value - tween.first.value,
            //     tween.first.value +
            //       (tween.last.value - tween.first.value) * value
            //   );
            // }

            SET_PROPERTY(
              item.object,
              animation.property,
              tween.first.value + (tween.last.value - tween.first.value) * value
            );

            // item.object.position.x =
            // tween.first.value + (tween.last.value - tween.first.value) * value;
            // console.log(
            //   tween.first.value + (tween.last.value - tween.first.value) * value
            // );
          } else {
            let kf = animation.keyframes.find((kf) => kf.frame === frames);
            if (kf && kf.inherit) {
              kf.value = _.get(item.object, animation.property);
            }

            if (kf) {
              SET_PROPERTY(item.object, animation.property, kf.value);
            }
          }
        }
      }
    }
  }

  load(obj) {
    this.fps = obj.fps;
    this.reverse_in = obj.reverse_in;
    this.play_on_engine_load = obj.play_on_engine_load;
    this.name = obj.name;
    this.loop = obj.loop || false;
    this.tracks = [];
    this.conditional_script.code = obj.conditional_script?.code;
    for (let i = 0; i < obj.tracks.length; i++) {
      //TIME SHIFTING
      // obj.tracks[i].animations.forEach(anim => {
      //   anim.keyframes.forEach(kf => {
      //     if (kf.frame > 1) {
      //       kf.frame *= 4;
      //     }
      //   });
      // });

      this.tracks.push({
        object: obj.tracks[i].object,
        animations: obj.tracks[i].animations ? obj.tracks[i].animations : [],
      });
    }

    this.duration = obj.duration;

    // if (this.tracks.length > 0) {
    //   for (var i = 0; i < this.tracks.length; i++) {
    //     for (var c = 0; c < this.tracks[i].animations.length; c++) {
    //       for (
    //         var y = 0;
    //         y < this.tracks[i].animations[c].keyframes.length;
    //         y++
    //       ) {
    //         if (
    //           this.tracks[i].animations[c].keyframes[y].frame > this.duration
    //         ) {
    //           this.duration =
    //             this.tracks[i].animations[c].keyframes[y].frame - 1;
    //         }
    //       }
    //     }
    //   }
    // }
  }

  save() {
    return {
      name: this.name,
      tracks: this.tracks.map((item) => {
        return {
          objectName: item.object.name,
          animations: item.animations,
          duration: item.duration,
          loop: item.loop,
          fps: item.fps,
        };
      }),
    };
  }

  lerpColor(a, b, amount) {
    var ah = parseInt(a.replace(/#/g, ""), 16),
      ar = ah >> 16,
      ag = (ah >> 8) & 0xff,
      ab = ah & 0xff,
      bh = parseInt(b.replace(/#/g, ""), 16),
      br = bh >> 16,
      bg = (bh >> 8) & 0xff,
      bb = bh & 0xff,
      rr = ar + amount * (br - ar),
      rg = ag + amount * (bg - ag),
      rb = ab + amount * (bb - ab);

    return (
      "#" +
      (((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1)
    );
  }
}

export default Timeline;
