import Timeline from "../Timeline";
// import json from "../../prject.json";
import uuid from "uuid/v4";
import SpriteMaskFilter_Alpha from "./SpriteMaskFilter_Alpha";
import {
  setProperty as SET_PROPERTY,
  gradient,
  redrawCircle,
  redrawRectangle,
} from "../../Scripts/PropertyUtils";
import moment from "moment";
import ClockManager from "../ClockManager";
import { DropShadowFilter } from "@pixi/filter-drop-shadow";
import HeatMap from "../HeatMap.js";

const PIXI = window.PIXI;

class MyImage extends PIXI.Sprite {
  constructor(props) {
    super(props);
    this.size_to_fit_padding = {
      height: 0,
      width: 0,
    };
  }

  static from(source, options) {
    const texture =
      source instanceof PIXI.Texture
        ? source
        : PIXI.Texture.from(source, options);

    return new MyImage(texture);
  }

  render(renderer) {
    if (this.size_to_fit && this.size_to_fit.length > 0) {
      let width = 0;
      let height = 0;
      let x_min = 0;
      let x_max = 0;
      let y_min = 0;
      let y_max = 0;

      this.size_to_fit
        .filter((item) => item.visible)
        .map((item, index) => {
          x_min = Math.min(index === 0 ? item.x : x_min, item.x);
          x_max = Math.max(x_max, item.x + item.width);
          y_min = Math.min(index === 0 ? item.y : y_min, item.y);
          y_max = Math.max(y_max, item.y + item.height);
        });
      width = x_max - x_min;
      height = y_max - y_min;
      this.width = width + (parseInt(this.size_to_fit_padding.width) || 0);
      this.height = height + (parseInt(this.size_to_fit_padding.height) || 0);
    }
    super.render(renderer);
  }
}
class MyGraphics extends PIXI.Graphics {
  _anchorX = 0;
  _anchorY = 0;

  constructor(props) {
    super(props);
    this.size_to_fit_padding = {
      height: 0,
      width: 0,
    };
  }

  set anchorX(value) {
    this._anchorX = value;
    this.pivot.x = (value * this.width) / this.scale.x;
  }

  get anchorX() {
    return this._anchorX;
  }

  set anchorY(value) {
    this._anchorY = value;
    this.pivot.y = (value * this.height) / this.scale.y;
  }

  get anchorY() {
    return this._anchorY;
  }

  updateAnchor() {
    this.anchorX = this._anchorX;
    this.anchorY = this._anchorY;
  }

  render(renderer) {
    super.render(renderer);
  }
}

class MyConatiner extends PIXI.Container {
  _anchorX = 0;
  _anchorY = 0;

  set anchorX(value) {
    this._anchorX = value;
    this.pivot.x = (value * this.width) / this.scale.x;
  }

  get anchorX() {
    return this._anchorX;
  }

  set anchorY(value) {
    this._anchorY = value;
    this.pivot.y = (value * this.height) / this.scale.y;
  }

  get anchorY() {
    return this._anchorY;
  }

  updateAnchor() {
    this.anchorX = this._anchorX;
    this.anchorY = this._anchorY;
  }

  render(renderer) {
    super.render(renderer);
  }
}

class MyText extends PIXI.Text {
  formatTime(time, format) {
    if (moment(time, [moment.ISO_8601, "HH:mm"]).isValid()) {
      return moment(time, [moment.ISO_8601, "HH:mm"]).format(format);
    } else {
      return time;
    }
  }

  get text() {
    return this._text;
  }
  set text(text) {
    text = String(text === null || text === undefined ? "" : text);

    if (this._text === text) {
      return;
    }

    if (this.text_transform?.uppercase) {
      text = text?.toUpperCase();
    }
    if (this.text_transform?.lowercase) {
      text = text?.toLowerCase();
    }

    this._text = text;

    this.dirty = true;
  }
}

class Clock extends PIXI.Text {
  time = 0;
  start_from = 0;
  status = "STOPPED";
  prev_tick = 0;
  format = "HH:mm:ss";
  clock_type = "TIME_OF_DAY";
  stop_at_zero = true;

  get text() {
    return this._text;
  }
  set text(text) {
    text = String(text === null || text === undefined ? "" : text);

    if (this._text === text && this._text === this._text?.toUpperCase()) {
      return;
    }
    this._text = text;
    if (this.text_transform?.uppercase) {
      this._text = this._text?.toUpperCase();
    }

    if (this.text_transform?.lowercase) {
      this._text = this._text?.toLowerCase();
    }

    this.dirty = true;
  }

  start(time) {
    this.status = "RUNNING";

    if (time >= 0) {
      if (this.clock_type !== "COUNTDOWN") {
        this.start_from = moment().valueOf() - time;
      } else {
        this.start_from = moment().valueOf() + time * 1000;
      }
    }

    this.time = this.start_from;

    this.paused_time_total = 0;
  }

  stop(time) {
    this.status = "STOPPED";
    this.paused_time = moment().valueOf();
  }

  resume() {
    this.status = "RUNNING";
    this.paused_time_total += moment().valueOf() - this.paused_time;
    this.paused_time = 0;
  }

  reset(time) {
    this.status = "STOPPED";
    this.paused_time_total = 0;
    if (time >= 0) {
      if (this.clock_type !== "COUNTDOWN") {
        this.start_from = moment().valueOf() - time;
      } else {
        this.start_from = moment().valueOf() + time * 1000;
      }
    }

    if (this.clock_type === "COUNTDOWN") {
      this.time = this.start_from - moment().valueOf() - this.paused_time_total;
    } else {
      this.time = moment().valueOf() - this.start_from - this.paused_time_total;
    }
    this.prev_tick = null;
    SET_PROPERTY(this, "text", this.formatText());
  }
  update_clock() {
    if (this.status === "RUNNING") {
      let elapsed = 0;
      if (this.clock_type === "COUNTDOWN") {
        elapsed = this.start_from - moment().valueOf() + this.paused_time_total;
      } else {
        elapsed = moment().valueOf() - this.start_from - this.paused_time_total;
      }

      if (this.clock_type === "COUNTUP") {
        this.time = elapsed;
      } else if (this.clock_type === "COUNTDOWN") {
        this.time = elapsed;
        if (this.stop_at_zero && this.time < 0) {
          this.time = 0;
        }
      }
    }
    if (this.clock_type === "TIME_OF_DAY") {
      SET_PROPERTY(this, "text", moment().format(this.format));
    } else {
      SET_PROPERTY(this, "text", this.formatText());
    }

    this.prev_tick = moment().valueOf();
  }

  formatText() {
    let duration = moment.duration(this.time, "ms");
    if (this.format.indexOf("h") > -1) {
      return this.format
        .replace("hh", parseInt(duration.asHours()).toString().padStart(2, "0"))
        .replace("h", parseInt(duration.asHours().toString()))
        .replace("mm", parseInt(duration.minutes()).toString().padStart(2, "0"))
        .replace("m", parseInt(duration.minutes().toString()))
        .replace("ss", parseInt(duration.seconds()).toString().padStart(2, "0"))
        .replace("s", parseInt(duration.seconds()).toString())
        .replace(
          "zz",
          parseInt(duration.milliseconds() / 10)
            .toString()
            .padStart(2, "0")
        )
        .replace("z", parseInt(duration.milliseconds() / 100).toString());
    } else {
      return this.format
        .replace(
          "mm",
          parseInt(duration.asMinutes()).toString().padStart(2, "0")
        )
        .replace("m", parseInt(duration.asMinutes().toString()))
        .replace("ss", parseInt(duration.seconds()).toString().padStart(2, "0"))
        .replace("s", parseInt(duration.seconds()).toString())
        .replace(
          "zz",
          parseInt(duration.milliseconds() / 10)
            .toString()
            .padStart(2, "0")
        )
        .replace("z", parseInt(duration.milliseconds() / 100).toString());
    }
  }
}

class Scene {
  constructor(
    name,
    stage,
    _id,
    apolloClient,
    user,
    project_id,
    project,
    archived = false
  ) {
    PIXI.Loader.shared.onError.add((err) => {
      debugger;
    });
    this.scene = new PIXI.Container();
    this.apolloClient = apolloClient;
    this.scene.renderable = false;
    this.scene.name = "Root";
    this.scene.uuid = uuid();
    this.project_id = project_id;
    this.project = project;
    this.name = name;
    this.stage = stage;
    this.timelines = [new Timeline("IN", this), new Timeline("OUT", this)];
    this.activeTimeline = this.timelines[0];
    this.state = 0;
    this.scene.sortableChildren = true;
    this.scene.visible = true;
    this.width = 1920;
    this.height = 1080;
    this.folder = "UNIVERSAL";
    this._id = _id || "";
    this.aspect = "16x9";
    this.football = {};
    this.basketball = {};
    this.rugby = {};
    this.climbing = {};
    this.gaa = {};
    this.netball = {};
    this.user = user;
    this.live_enabled = true;

    this.archived = archived;

    // if (this.name === 'LOOP') {
    //   this.addVideo();
    // }
  }

  rename(name) {
    this.name = name;
  }

  setup() {
    this.stage.addChild(this.scene);
    this.addGroup("Root");
    // this.group = new PIXI.Container();
    // this.group.type = "GROUP";
    // this.scene.addChild(this.group);
    // this.addRectangle();
    // this.addText();
    // this.save();
  }

  load(json) {
    if (!this.archived) {
      this.importScene(json);
    }
  }

  onEngineStartUp() {
    for (let i = 0; i < this.timelines.length; i++) {
      let tl = this.timelines[i];
      if (tl.play_on_engine_load) {
        tl.play();
      }
    }
  }

  addRectangle() {
    let rectangle = new MyGraphics();
    rectangle.tint = 0xffffff;
    rectangle.fillColor = 0xdb0a41;
    rectangle.beginFill(0xdb0a41);
    rectangle.width = 200;
    rectangle.height = 60;
    rectangle.drawRoundedRect(0, 0, 200, 60, 0);
    rectangle.radius = 0;
    rectangle.endFill();
    rectangle.x = 50;
    rectangle.y = 50;
    rectangle.name = "Background";
    rectangle.type = "RECTANGLE";
    rectangle.uuid = uuid();
    rectangle.control = {
      id: 0,
      name: "",
      editables: [],
    };
    rectangle.filters = [];
    rectangle.drop_shadow = {
      enabled: false,
      offset: { x: 4, y: 4 },
    };
    this.scene.addChild(rectangle);
    return rectangle;
  }

  addText() {
    let style = new PIXI.TextStyle({
      fontFamily: "Arial",
      fontSize: 36,
      fill: "white",
    });
    let message = new MyText("XXXXXXX", style);
    message.colour = "white";
    message.name = "Text";
    message.type = "TEXT";
    message.uuid = uuid();
    message.text_transform = {
      uppercase: false,
      lowercase: false,
    };
    message.control = {
      id: 0,
      name: "",
      editables: [],
    };
    message.filters = [];
    message.drop_shadow = {
      enabled: false,
      offset: { x: 4, y: 4 },
    };
    this.scene.addChild(message);
    return message;
  }

  addInput({ parent }) {
    const texture = PIXI.Texture.from(document.getElementById("input_1"));
    const sprite = new PIXI.Sprite(texture);
    sprite.width = 1920;
    sprite.height = 1080;
    sprite.x = 0;
    sprite.y = 0;
    sprite.type = "INPUT";
    sprite.name = "INPUT 1";
    sprite.uuid = uuid();
    sprite.filters = [];
    sprite.drop_shadow = {
      enabled: false,
      offset: { x: 4, y: 4 },
    };
    if (parent) {
      parent.addChild(sprite);
    } else {
      this.scene.addChild(sprite);
    }

    this.update(texture);
    return sprite;
  }

  update(texture) {
    texture.update();
    requestAnimationFrame(() => {
      this.update(texture);
    });
  }

  addClock() {
    let style = new PIXI.TextStyle({
      fontFamily: "Arial",
      fontSize: 36,
      fill: "white",
    });
    let clock = new Clock("00:00", style);
    clock.colour = "white";
    clock.name = "Clock";
    clock.format = "mm:ss";
    clock.type = "CLOCK";
    clock.uuid = uuid();
    clock.control = {
      id: 0,
      name: "",
      editables: [],
    };
    clock.filters = [];
    clock.drop_shadow = {
      enabled: false,
      offset: { x: 4, y: 4 },
    };
    this.scene.addChild(clock);
    return clock;
  }

  addHeatMap({ addToScene = true }) {
    let canvas = window.document.createElement("canvas");
    canvas.width = 294;
    canvas.height = 172;
    canvas.style.width = 294 + "px";
    canvas.style.height = 172 + "px";
    let scale_x = 294 / 100;
    let scale_y = 172 / 100;
    let data = [
      [275.478, 118.336, 1],
      [288.12, 118.164, 1],
      [183.16199999999998, 118.336, 1],
      [286.94399999999996, 137.94400000000002, 1],
      [237.846, 125.044, 1],
      [276.948, 130.72, 1],
      [211.68, 150.672, 1],
      [206.682, 172, 1],
      [214.32600000000002, 156.348, 1],
      [210.21, 160.476, 1],
      [189.92399999999998, 147.232, 1],
      [215.50199999999998, 151.016, 1],
      [278.12399999999997, 116.27199999999999, 1],
      [183.75, 149.984, 1],
      [170.52, 127.45199999999998, 1],
      [256.662, 143.62, 1],
      [201.096, 145.168, 1],
      [205.212, 111.62800000000001, 1],
      [209.62199999999999, 156.864, 1],
      [283.416, 140.696, 1],
      [171.99, 155.316, 1],
    ];
    let heat = HeatMap(canvas).data(data).max(5);
    heat.radius(18, 14);
    heat.draw();

    let texture = PIXI.Texture.from(canvas);
    let sprite = new PIXI.Sprite(texture);
    sprite.heat = heat;
    sprite.type = "HEATMAP";
    sprite.name = "Heat Map";
    if (addToScene) {
      this.scene.addChild(sprite);
    }
    return sprite;
  }

  addGroup(name) {
    let container = new MyConatiner();
    container.name = name;
    container.type = "GROUP";
    container.uuid = uuid();
    container.expanded = false;
    container.sortableChildren = true;
    container.control = {
      id: 0,
      name: "",
      editables: [],
    };
    container.filters = [];
    container.drop_shadow = {
      enabled: false,
      colour: "#000000",
      offset: { x: 4, y: 4 },
    };
    this.scene.addChild(container);
    return container;
  }

  addCircle() {
    let circle = new MyGraphics();
    circle.tint = 0xffffff;
    circle.fillColor = 0xdb0a41;
    circle.beginFill(0xdb0a41);
    circle.drawCircle(0, 0, 32);
    circle.radius = 32;
    circle.endFill();
    circle.x = 0;
    circle.y = 0;
    circle.control = {
      id: 0,
      name: "",
      editables: [],
    };
    circle.uuid = uuid();
    circle.name = "Circle";
    circle.type = "CIRCLE";
    circle.filters = [];
    circle.drop_shadow = {
      enabled: false,
      offset: { x: 4, y: 4 },
    };
    this.scene.addChild(circle);
    return circle;
  }

  addImage(name) {
    var sprite = MyImage.from("/images/PlaceHolder.png");
    sprite.x = 0;
    sprite.y = 0;
    sprite.name = name;
    sprite.type = "IMAGE";
    sprite.uuid = uuid();
    sprite.control = {
      id: 0,
      name: "",
      editables: [],
    };
    sprite.filters = [];
    sprite.drop_shadow = {
      enabled: false,
      offset: { x: 4, y: 4 },
    };
    this.scene.addChild(sprite);
    return sprite;
  }

  addSequence() {
    const textures = [];
    for (let i = 1; i < 52; i++) {
      const texture = PIXI.Texture.from(
        `http://localhost:6060/assets/sequence/efl/LineUpIn/frame_${i
          .toString()
          .padStart(4, "0")}.png`
      );
      textures.push(texture);
    }
    const sequence = new PIXI.AnimatedSprite(textures);

    sequence.autoUpdate = false;
    sequence.animationSpeed = 0.5;
    sequence.uuid = uuid();
    sequence.type = "VIDEO";
    sequence.name = "Sequence";
    sequence.loop = false;
    sequence.filters = [];
    sequence.drop_shadow = {
      enabled: false,
      offset: { x: 4, y: 4 },
    };
    this.scene.addChild(sequence);
  }

  addVideo(name) {
    var videoSprite = new PIXI.Sprite();
    let video_src =
      window.projectServerURL + "assets/videos/" + this.user._id + "/VIDEO.mp4";
    videoSprite.texture = PIXI.Texture.from(video_src);
    videoSprite.src = video_src;
    videoSprite.texture.baseTexture.resource.source.loop = false;
    videoSprite.texture.baseTexture.resource.source.preload = "auto";
    videoSprite.texture.baseTexture.resource.source.mute = true;
    videoSprite.texture.baseTexture.resource.source.autoplay = false;
    videoSprite.texture.baseTexture.resource.autoPlay = false;
    videoSprite.type = "VIDEO";
    videoSprite.name = "VIDEO";
    videoSprite.uuid = uuid();
    videoSprite.control = {
      id: 0,
      name: "",
      editables: [],
    };
    videoSprite.filters = [];
    videoSprite.drop_shadow = {
      enabled: false,
      offset: { x: 4, y: 4 },
    };
    this.scene.addChild(videoSprite);
    return videoSprite;
  }

  addAudio(name) {
    let audio = new PIXI.Sprite();
    let audio_src =
      window.projectServerURL +
      "assets/audio/project/" +
      this.user._id +
      "/SS+_OSP_10SEC_PTXV03_MUSIC_LOOP.wav";
    audio.src = audio_src;
    audio.type = "AUDIO";
    audio.name = "AUDIO";
    try {
      audio.audio_player = new Audio(audio_src);
    } catch (err) {
      console.log(err);
    }
    this.scene.addChild(audio);
    return audio;
  }

  createTimeline() {
    let tl = new Timeline("T" + (this.timelines.length + 1), this);
    this.timelines.push(tl);
    this.activeTimeline = tl;
  }

  triggerTransition() {
    let timeline;
    if (this.state === 0) {
      timeline = this.timelines.find((tl) => {
        return tl.name == "IN";
      });
      this.state = 1;
    } else if (this.state === 1) {
      timeline = this.timelines.find((tl) => {
        return tl.name == "OUT";
      });
      this.state = 0;
    }
    timeline.restart();
    timeline.togglePlay();
    this.activeTimeline = timeline;
  }

  render(delta) {
    for (let i = 0; i < this.timelines.length; i++) {
      if (this.timelines[i].playingState === 1) {
        this.timelines[i].update(delta);
      }

      // if (
      //   this.timelines[i].playingState === 1 &&
      //   this.scene.visible === false &&
      //   this.timelines[i].frameNumber >= 1
      // ) {
      //   this.scene.visible = true;
      // }
    }

    let objects = this.getItemsByDataName({
      name: "Game Time",
      children: this.scene.children,
      template: "Rugby Clock",
      app: "clock",
    });

    objects.forEach((obj) => {
      obj.text = this.stadium_clock_time;
      let style = obj.style;
      if (style) {
        style.fontSize = style.original_size || style.fontSize;
        let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
        obj.width = parseInt(textMetrics.width);
        obj.height = parseInt(textMetrics.height);
        style.original_size = style.fontSize;
        while (obj.maxHeight > 0 && obj.height > obj.maxHeight) {
          style.fontSize -= 1;

          let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
          obj.height = parseInt(textMetrics.height);
        }
        if (obj.maxWidth > 0 && obj.width > obj.maxWidth) {
          obj.width = obj.maxWidth;
        }
      }
    });

    objects = this.getItemsByDataName({
      name: "Game Clock",
      children: this.scene.children,
      template: "Rugby Clock",
      app: "clock",
    });

    objects.forEach((obj) => {
      obj.text = this.stadium_game_clock_time;
      let style = obj.style;
      if (style) {
        style.fontSize = style.original_size || style.fontSize;
        let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
        obj.width = parseInt(textMetrics.width);
        obj.height = parseInt(textMetrics.height);
        style.original_size = style.fontSize;
        while (obj.maxHeight > 0 && obj.height > obj.maxHeight) {
          style.fontSize -= 1;

          let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
          obj.height = parseInt(textMetrics.height);
        }
        if (obj.maxWidth > 0 && obj.width > obj.maxWidth) {
          obj.width = obj.maxWidth;
        }
      }
    });

    objects = this.getItemsByDataName({
      name: "Shot Clock",
      children: this.scene.children,
      template: "Rugby Clock",
      app: "clock",
    });

    objects.forEach((obj) => {
      obj.text = this.stadium_shot_clock_time;
      let style = obj.style;
      if (style) {
        style.fontSize = style.original_size || style.fontSize;
        let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
        obj.width = parseInt(textMetrics.width);
        obj.height = parseInt(textMetrics.height);
        style.original_size = style.fontSize;
        while (obj.maxHeight > 0 && obj.height > obj.maxHeight) {
          style.fontSize -= 1;

          let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
          obj.height = parseInt(textMetrics.height);
        }
        if (obj.maxWidth > 0 && obj.width > obj.maxWidth) {
          obj.width = obj.maxWidth;
        }
      }
    });

    objects = this.getItemsByDataName({
      name: "Type",
      children: this.scene.children,
      template: "Climbing Clock",
      app: "clock",
    });

    objects.forEach((obj) => {
      obj.text = this.climbing_clock_type;
      let style = obj.style;
      if (style) {
        style.fontSize = style.original_size || style.fontSize;
        let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
        obj.width = parseInt(textMetrics.width);
        obj.height = parseInt(textMetrics.height);
        style.original_size = style.fontSize;
        while (obj.maxHeight > 0 && obj.height > obj.maxHeight) {
          style.fontSize -= 1;

          let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
          obj.height = parseInt(textMetrics.height);
        }
        if (obj.maxWidth > 0 && obj.width > obj.maxWidth) {
          obj.width = obj.maxWidth;
        }
      }
    });
    objects = this.getItemsByDataName({
      name: "Boulder Time",
      children: this.scene.children,
      template: "Climbing Clock",
      app: "clock",
    });

    objects.forEach((obj) => {
      obj.text = this.climbing_clock_boulder_time;
      let style = obj.style;
      if (style) {
        style.fontSize = style.original_size || style.fontSize;
        let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
        obj.width = parseInt(textMetrics.width);
        obj.height = parseInt(textMetrics.height);
        style.original_size = style.fontSize;
        while (obj.maxHeight > 0 && obj.height > obj.maxHeight) {
          style.fontSize -= 1;

          let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
          obj.height = parseInt(textMetrics.height);
        }
        if (obj.maxWidth > 0 && obj.width > obj.maxWidth) {
          obj.width = obj.maxWidth;
        }
      }
    });

    objects = this.getItemsByDataName({
      name: "Left Time",
      children: this.scene.children,
      template: "Climbing Clock",
      app: "clock",
    });

    objects.forEach((obj) => {
      obj.text = this.climbing_clock_left_time;
      let style = obj.style;
      if (style) {
        style.fontSize = style.original_size || style.fontSize;
        let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
        obj.width = parseInt(textMetrics.width);
        obj.height = parseInt(textMetrics.height);
        style.original_size = style.fontSize;
        while (obj.maxHeight > 0 && obj.height > obj.maxHeight) {
          style.fontSize -= 1;

          let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
          obj.height = parseInt(textMetrics.height);
        }
        if (obj.maxWidth > 0 && obj.width > obj.maxWidth) {
          obj.width = obj.maxWidth;
        }
      }
    });

    objects = this.getItemsByDataName({
      name: "Left Reaction",
      children: this.scene.children,
      template: "Climbing Clock",
      app: "clock",
    });

    objects.forEach((obj) => {
      obj.text = this.climbing_clock_left_reaction;
      let style = obj.style;
      if (style) {
        style.fontSize = style.original_size || style.fontSize;
        let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
        obj.width = parseInt(textMetrics.width);
        obj.height = parseInt(textMetrics.height);
        style.original_size = style.fontSize;
        while (obj.maxHeight > 0 && obj.height > obj.maxHeight) {
          style.fontSize -= 1;

          let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
          obj.height = parseInt(textMetrics.height);
        }
        if (obj.maxWidth > 0 && obj.width > obj.maxWidth) {
          obj.width = obj.maxWidth;
        }
      }
    });

    objects = this.getItemsByDataName({
      name: "Left State",
      children: this.scene.children,
      template: "Climbing Clock",
      app: "clock",
    });

    objects.forEach((obj) => {
      obj.text = this.climbing_clock_left_state;
      let style = obj.style;
      if (style) {
        style.fontSize = style.original_size || style.fontSize;
        let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
        obj.width = parseInt(textMetrics.width);
        obj.height = parseInt(textMetrics.height);
        style.original_size = style.fontSize;
        while (obj.maxHeight > 0 && obj.height > obj.maxHeight) {
          style.fontSize -= 1;

          let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
          obj.height = parseInt(textMetrics.height);
        }
        if (obj.maxWidth > 0 && obj.width > obj.maxWidth) {
          obj.width = obj.maxWidth;
        }
      }
    });

    objects = this.getItemsByDataName({
      name: "Right Time",
      children: this.scene.children,
      template: "Climbing Clock",
      app: "clock",
    });

    objects.forEach((obj) => {
      obj.text = this.climbing_clock_right_time;
      let style = obj.style;
      if (style) {
        style.fontSize = style.original_size || style.fontSize;
        let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
        obj.width = parseInt(textMetrics.width);
        obj.height = parseInt(textMetrics.height);
        style.original_size = style.fontSize;
        while (obj.maxHeight > 0 && obj.height > obj.maxHeight) {
          style.fontSize -= 1;

          let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
          obj.height = parseInt(textMetrics.height);
        }
        if (obj.maxWidth > 0 && obj.width > obj.maxWidth) {
          obj.width = obj.maxWidth;
        }
      }
    });

    objects = this.getItemsByDataName({
      name: "Right Reaction",
      children: this.scene.children,
      template: "Climbing Clock",
      app: "clock",
    });

    objects.forEach((obj) => {
      obj.text = this.climbing_clock_right_reaction;
      let style = obj.style;
      if (style) {
        style.fontSize = style.original_size || style.fontSize;
        let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
        obj.width = parseInt(textMetrics.width);
        obj.height = parseInt(textMetrics.height);
        style.original_size = style.fontSize;
        while (obj.maxHeight > 0 && obj.height > obj.maxHeight) {
          style.fontSize -= 1;

          let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
          obj.height = parseInt(textMetrics.height);
        }
        if (obj.maxWidth > 0 && obj.width > obj.maxWidth) {
          obj.width = obj.maxWidth;
        }
      }
    });

    objects = this.getItemsByDataName({
      name: "Right State",
      children: this.scene.children,
      template: "Climbing Clock",
      app: "clock",
    });

    objects.forEach((obj) => {
      obj.text = this.climbing_clock_right_state;
      let style = obj.style;
      if (style) {
        style.fontSize = style.original_size || style.fontSize;
        let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
        obj.width = parseInt(textMetrics.width);
        obj.height = parseInt(textMetrics.height);
        style.original_size = style.fontSize;
        while (obj.maxHeight > 0 && obj.height > obj.maxHeight) {
          style.fontSize -= 1;

          let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
          obj.height = parseInt(textMetrics.height);
        }
        if (obj.maxWidth > 0 && obj.width > obj.maxWidth) {
          obj.width = obj.maxWidth;
        }
      }
    });
  }

  save() {
    let sceneExport = {
      // name: this.name + "_ASPECT" + this.aspect,
      name: this.name,
      basketball: this.basketball,
      football: this.football,
      rugby: this.rugby,
      climbing: this.climbing,
      gaa: this.gaa,
      netball: this.netball,
      neverno: this.neverno,
      stadium_clock: this.stadium_clock,
      width: this.width,
      height: this.height,
      folder: this.folder,
      aspect: this.aspect || "16x9",
      order: this.order,
      live_enabled: this.live_enabled,
      transition_layer: this.transition_layer,
      background_scene: this.background_scene,
      canvas_width: this.canvas_width,
      canvas_height: this.canvas_height,
      archived: this.archived,
      timelines: this.timelines.map((tl) => {
        let t = { ...tl };

        delete t.scene;
        delete t.conditional_script.codeRunner;
        return {
          ...t,
          tracks: tl.tracks
            .filter((item) => item.object !== undefined)
            .map((track) => {
              return {
                ...track,
                animations: track.animations?.map((anim) => {
                  let keyframes = anim.keyframes.map((kf) => {
                    delete kf.codeRunner;
                    return { ...kf };
                  });

                  return { ...anim, keyframes };
                }),
                object: track.object.uuid
                  ? track.object.uuid
                  : track.object.name,
              };
            }),
        };
      }),
    };

    sceneExport.scene = this.objectToJson(Object.assign({}, this.scene));
    sceneExport.scene.type = "SCENE";
    this.isCyclic(sceneExport);

    return sceneExport;
  }

  isCyclic(obj) {
    var seenObjects = [];

    function detect(obj) {
      if (obj && typeof obj === "object") {
        if (seenObjects.indexOf(obj) !== -1) {
          return true;
        }
        seenObjects.push(obj);
        for (var key in obj) {
          // console.log(obj);
          if (obj.hasOwnProperty(key) && detect(obj[key])) {
            console.log(obj, "cycle at " + key);
            return true;
          }
        }
      }
      return false;
    }

    return detect(obj);
  }

  objectToJson(object) {
    let entries = Object.entries(object).filter((item) => item[0][0] !== "_");
    let obj = {};
    // entries.forEach(element => {
    //   obj[element[0]] = element[1];
    // });
    // delete obj.parent;
    // delete obj.scope;
    // delete obj.indices;
    // delete obj.indexBuffer;
    // delete obj.tempDisplayObjectParent;

    obj.children = [];

    for (let i = 0; i < object.children.length; i++) {
      let width = object.children[i].width;
      let height = object.children[i].height;
      obj.children.push(
        this.objectToJson(
          Object.assign({ width: width, height: height }, object.children[i])
        )
      );
    }

    obj.uuid = object.uuid;
    obj.type = object.type;
    obj.name = object.name;
    obj.tint = object.tint;
    obj.x = object.transform.position.x;
    obj.y = object.transform.position.y;

    obj.control = object.control;
    obj.width = object.width;
    obj.height = object.height;

    obj.alpha = object.alpha;

    if (object.type === "GROUP" && object.filters?.length > 0) {
      obj.alpha = object.filters?.find((f) => f.name === "ALPHA")?.alpha;
    }

    obj.size_to_fit = object.size_to_fit
      ?.filter((item) => item?.uuid)
      ?.map((item) => item.uuid);
    obj.size_to_fit_padding = object.size_to_fit_padding;

    obj.code = object.code;

    if (object.transform._rotation) {
      obj.rotation = object.transform._rotation;
    }

    if (object.autoFollow) {
      obj.autoFollow = object.autoFollow.uuid;
      obj.autoFollowPadding = object.autoFollowPadding;
    }

    if (object._geometry) {
      obj.graphicsData = object._geometry.graphicsData[0].shape;
      obj.fillColor =
        object.fillColor || object._geometry.graphicsData[0].fillStyle.color;
    }

    if (object.maxWidth && object.maxWidth > 0) {
      obj.maxWidth = object.maxWidth;
    } else {
      obj.maxWidth = 0;
    }

    if (object._anchor) {
      obj.anchor = {
        x: object._anchor.x,
        y: object._anchor.y,
      };
    } else {
      obj.anchorX = object._anchorX;
      obj.anchorY = object._anchorY;
    }

    if (object.transform.skew) {
      obj.skew = { x: object.transform.skew.x, y: object.transform.skew.y };
    }

    if (object.transform.scale) {
      obj.scale = {
        x: object.transform.scale.x,
        y: object.transform.scale.y,
      };
    }

    if (object.dataBinds) {
      obj.dataBinds = object.dataBinds;
    }
    obj.blendMode = object.blendMode;
    obj.mask = object._mask ? object._mask.uuid : null;
    obj.alpha_mask = object.filters?.find(
      (f) => f.class_name === "SpriteMaskFilter_Alpha"
    )?.maskSprite?.uuid;
    if (object.gradient) {
      obj.gradient = object.gradient;
    }
    obj.visible = object.visible;
    obj.drop_shadow = object.drop_shadow;
    // delete obj.posiiton;
    // delete obj.transform;
    switch (object.type) {
      case "CLOCK":
        obj.style = object._style;
        obj.text = object._text;
        obj.format = object.format;
        obj.start_from = object.start_from;
        obj.clock_type = object.clock_type;
        obj.stop_at_zero = object.stop_at_zero;
        obj.count = object.count;

        obj.colour = object.colour;
        break;
      case "CIRCLE":
        obj.colour = object.colour;
        break;
      case "HEATMAP":
        break;
      case "TEXT":
        obj.style = object._style;
        obj.text = object._text;
        obj.text_transform = object.text_transform;
        obj.maxHeight = Math.max(0, object.maxHeight);
        obj.colour = object.colour;
        obj.rtl = object.rtl;
        break;
      case "VIDEO":
        obj.loop = object.loop;

        obj.src = object.src
          .replace(
            window.projectServerURL + "assets/videos/" + this.user._id + "/",
            ""
          )
          .replace(
            window.projectServerURL +
              "assets/videos/project/" +
              this.project_id +
              "/",
            ""
          );

        break;
      case "AUDIO":
        obj.loop = object.loop;
        obj.src = object.src
          .replace(
            window.projectServerURL + "assets/audio/" + this.user._id + "/",
            ""
          )
          .replace(
            window.projectServerURL +
              "assets/audio/project/" +
              this.project_id +
              "/",
            ""
          );

        break;
      case "IMAGE":
        obj.src = object.src
          .replace(
            window.projectServerURL + "assets/images/" + this.user._id + "/",
            ""
          )
          .replace(
            window.projectServerURL +
              "assets/images/project/" +
              this.project_id +
              "/",
            ""
          );

        obj.tint =
          object._tint !== null && object._tint !== undefined
            ? object._tint
            : 0xffffff;
        break;
      default:
        break;
    }

    return obj;
  }

  importScene(obj) {
    // this.addRectangle();
    // return;

    this.name = obj.name.replace("_ASPECT" + obj.aspect, "");
    this.width = obj.width || 1920;
    this.height = obj.height || 1080;
    this.folder = obj.folder;
    this.aspect = obj.aspect || "16x9";
    this.basketball = obj.basketball || {};
    this.climbing = obj.climbing || {};
    this.football = obj.football || {};
    this.rugby = obj.rugby || {};
    this.gaa = obj.gaa || {};
    this.netball = obj.netball || {};
    this.neverno = obj.neverno || {};
    this.stadium_clock = obj.stadium_clock || {};
    this.canvas_width = obj.canvas_width || 1920;
    this.canvas_height = obj.canvas_height || 1080;
    this.archived = obj.archived === true ? true : false;
    if (window.clockServer || this.stadium_clock.server) {
      let clock = ClockManager.getInstance().getClock({
        url: window.clockServer ?? this.stadium_clock.server,
      });
      clock.onClockUpdate((e) => {
        if (e.detail.climbing) {
          this.climbing_clock_type = e.detail.climbing.climbing_type;
          this.climbing_clock_boulder_time =
            e.detail.climbing.climbing_boulder_time;
          this.climbing_clock_left_time = e.detail.climbing.climbing_left_time;
          this.climbing_clock_left_reaction =
            e.detail.climbing.climbing_left_reaction;
          this.climbing_clock_left_state =
            e.detail.climbing.climbing_left_state;
          this.climbing_clock_right_time =
            e.detail.climbing.climbing_right_time;
          this.climbing_clock_right_reaction =
            e.detail.climbing.climbing_right_reaction;
          this.climbing_clock_right_state =
            e.detail.climbing.climbing_right_state;
        } else {
          this.stadium_clock_time = e.detail.time;
          this.stadium_shot_clock_time = e.detail.shot_clock;
          this.stadium_game_clock_time = e.detail.game_clock;
        }
      });
    }
    this.order = obj.order;
    this.live_enabled =
      obj.live_enabled !== undefined ? obj.live_enabled : true;
    this.scene.zIndex = this.order || 0;
    this.background_scene = obj.background_scene;
    this.transition_layer = obj.transition_layer;
    this.ids = [];

    this.importObject(obj.scene, this.scene, false);
    this.loadMasks(this.scene);
    this.loadDropShadows(this.scene);
    this.loadSizeToFit(this.scene);
    this.loadAutoFollow(this.scene);
    this.stage.addChild(this.scene);

    this.timelines = [];

    for (let i = 0; i < obj.timelines.length; i++) {
      let tl = new Timeline(obj.timelines[i].name, this);

      let tracks = obj.timelines[i].tracks.map((track) => {
        let obj = this.getItemByUUID(track.object, this.scene.children);
        return {
          ...track,
          object: obj ? obj : { name: track.object },
        };
      });
      obj.timelines[i].tracks = tracks;

      tl.load(obj.timelines[i], this.name);

      this.timelines.push(tl);
    }
    this.activeTimeline = this.timelines[0];
  }

  importAnimations(obj, animations) {
    animations.forEach((anim) => {
      let timeline = this.timelines.find((tl) => tl.name == anim.name);
      if (!timeline) {
        timeline = new Timeline(anim.name, this);
        this.timelines.push(timeline);
      }
      timeline.tracks.push({ ...anim.tracks[0], object: obj });
    });
  }

  importObject(obj, parent, newID, importAnimations = false) {
    let object;
    let user = this.project.user?._id;
    let id = this.project?.use_project_folder ? this.project._id : user;
    let prefix = this.project?.use_project_folder ? "project/" : "";

    if (obj.type === "SCENE") {
      object = parent;
    }
    let style = {};
    let textMetrics = {};
    switch (obj.type) {
      case "GROUP":
        object = new MyConatiner();

        object.name = obj.name;

        if (obj.x) {
          object.x = obj.x;
        } else if (obj.transform) {
          object.x = obj.transform.position.x;
        }
        if (obj.y) {
          object.y = obj.y;
        } else if (obj.transform) {
          object.y = obj.transform.position.y;
        }

        object.width = obj.width !== undefined ? obj.width : obj._width;
        object.height = obj.height !== undefined ? obj.height : obj._height;
        object.scale = obj.scale;
        object.anchorX = parseFloat(obj.anchorX) || 0;
        object.anchorY = parseFloat(obj.anchorY) || 0;

        object.updateAnchor();
        object.expanded = false;
        object.sortableChildren = true;
        parent.addChild(object);
        object.visible = obj.visible;

        break;
      case "RECTANGLE":
        object = new MyGraphics();

        object.name = obj.name;
        object.x = obj.x !== undefined ? obj.x : obj.transform.position.x;
        object.y = obj.y !== undefined ? obj.y : obj.transform.position.y;

        // object.tint = "white";

        if (obj.fillColor !== undefined) {
          object.beginFill(obj.fillColor || "#fff");
          object.fillColor = obj.fillColor || "#fff";
        }
        let width = obj.width == undefined ? obj.graphicsData.width : obj.width;
        let height =
          obj.height == undefined ? obj.graphicsData.height : obj.height;
        object.width = width;
        object.height = height;

        object.gradient = obj.gradient;
        if (obj.gradient && obj.gradient.enabled) {
          redrawRectangle({ item: object });
        } else if (obj.graphicsData) {
          object.radius = obj.graphicsData.radius;
          object.width = width === 0 ? 0.0001 : width;
          object.height = height === 0 ? 0.0001 : height;
          redrawRectangle({ item: object });
        } else if (obj.geometry?.graphicsData[0].x) {
          object.width = obj.geometry.graphicsData[0].width;
          object.height = obj.geometry.graphicsData[0].height;
          object.radius = obj.geometry.graphicsData[0].radius;
          redrawRectangle({ item: object });
        } else {
          object.width = obj.geometry.graphicsData[0].width;
          object.height = obj.geometry.graphicsData[0].height;
          object.radius = obj.geometry.graphicsData[0].radius;
          redrawRectangle({ item: object });
        }

        object.visible = obj.visible;
        // object.endFill();
        object.anchorX =
          parseFloat(obj.anchorX) || parseFloat(obj._anchorX) || 0;
        object.anchorY =
          parseFloat(obj.anchorY) || parseFloat(obj._anchorY) || 0;
        parent.addChild(object);
        break;
      case "CIRCLE":
        object = new MyGraphics();
        object.name = obj.name;
        object.x = obj.x !== undefined ? obj.x : obj.transform.position.x;
        object.y = obj.y !== undefined ? obj.y : obj.transform.position.y;

        // object.tint = "white";
        object.gradient = obj.gradient;
        if (obj.fillColor !== undefined) {
          object.beginFill(obj.fillColor);
          object.fillColor = obj.fillColor || "#fff";
        }

        if (obj.graphicsData) {
          object.radius = obj.graphicsData.radius;
          redrawCircle({ item: object });
        } else if (obj._geometry?.graphicsData?.[0]?.shape?.radius) {
          object.radius = obj._geometry?.graphicsData?.[0]?.shape?.radius;

          redrawCircle({ item: object });
        } else if (obj.geometry.graphicsData[0].radius) {
          object.radius = obj.geometry.graphicsData[0].radius;
          redrawCircle({ item: object });
        } else {
          object.radius = obj.geometry.graphicsData[0].shape.radius;

          redrawCircle({ item: object });
        }

        object.visible = obj.visible;

        object.anchorX = parseFloat(obj.anchorX) || 0;
        object.anchorY = parseFloat(obj.anchorY) || 0;
        parent.addChild(object);
        break;
      case "TEXT":
        style = new PIXI.TextStyle(
          obj?.gradient?.enabled
            ? {
                fillGradientType: obj.gradient.horizontal ? 0 : 1,
                fill: obj.gradient.palette?.map((i) => i.color),
                fillGradientStops: obj.gradient.palette?.map((i) =>
                  parseFloat(i.offset)
                ),
                fontSize: obj.style._fontSize,
              }
            : obj.style || obj._style
        );

        delete style.original_size;
        style.padding = 5;
        object = new MyText(obj.text || obj._text, style);
        object.gradient = obj.gradient;
        object.colour = obj.colour || style.fill;
        object.resolution = 2; //IMPROVES TEXT QUALITY
        object.maxHeight = obj.maxHeight || 0;
        object.text_transform = obj.text_transform;
        object.rtl = obj.rtl ?? false;
        if (!object.text_transform) {
          object.text_transform = {
            uppercase: false,
            lowercase: false,
          };
        }
        // object = new PIXI.BitmapText(obj.text, {
        //   font: "72px " + "SkyPremier",
        // });

        textMetrics = PIXI.TextMetrics.measureText(object.text, style);

        object.width = parseInt(textMetrics.width);
        object.name = obj.name;

        if (obj.x) {
          object.x = obj.x;
        } else if (obj.transform) {
          object.x = obj.transform.position.x;
        }
        if (obj.y) {
          object.y = obj.y;
        } else if (obj.transform) {
          object.y = obj.transform.position.y;
        }
        object.visible = obj.visible;

        // object.width = obj.width;
        // object.height = obj.height;

        parent.addChild(object);
        break;
      case "CLOCK":
        style = new PIXI.TextStyle(
          obj?.gradient?.enabled
            ? {
                fillGradientType: obj.gradient.horizontal ? 0 : 1,
                fill: obj.gradient.palette?.map((i) => i.color),
                fillGradientStops: obj.gradient.palette?.map((i) =>
                  parseFloat(i.offset)
                ),
                fontSize: obj.style._fontSize,
              }
            : obj.style || obj._style
        );

        delete style.original_size;
        style.padding = 5;
        object = new Clock(obj.text || obj._text, style);
        object.gradient = obj.gradient;
        object.resolution = 2; //IMPROVES TEXT QUALITY

        // object = new PIXI.BitmapText(obj.text, {
        //   font: "72px " + "SkyPremier",
        // });

        textMetrics = PIXI.TextMetrics.measureText(object.text, style);
        object.height = parseInt(textMetrics.height);
        object.width = parseInt(textMetrics.width);
        object.name = obj.name;
        object.count = obj.count;
        if (obj.x) {
          object.x = obj.x;
        } else if (obj.transform) {
          object.x = obj.transform.position.x;
        }
        if (obj.y) {
          object.y = obj.y;
        } else if (obj.transform) {
          object.y = obj.transform.position.y;
        }
        object.visible = obj.visible;
        object.format = obj.format;
        object.start_from = obj.start_from;
        object.clock_type = obj.clock_type;
        object.stop_at_zero = obj.stop_at_zero;

        parent.addChild(object);
        break;
      case "IMAGE":
        let src = "";
        if (
          obj.src
            ?.substring(obj.src.lastIndexOf("/") + 1)
            ?.replace(/ /g, "_")
            ?.indexOf("${user}") > -1
        ) {
          src =
            window.projectServerURL +
            "assets/images/" +
            user +
            "/" +
            obj.src
              ?.substring(obj.src.lastIndexOf("/") + 1)
              ?.replace(/ /g, "_")
              ?.replace("${user}", "");
        } else {
          src =
            window.projectServerURL +
            "assets/images/" +
            prefix +
            id +
            "/" +
            obj.src.substring(obj.src.lastIndexOf("/") + 1).replace(/ /g, "_");
        }
        if (PIXI.Loader.shared.resources[src]) {
          object = new MyImage(PIXI.Loader.shared.resources[src].texture);
        } else {
          object = MyImage.from(src, {
            resourceOptions: {
              autoLoad: false,
            },
          });

          object._texture.baseTexture.resource.load().catch((err) => {
            const event = new CustomEvent("ImageError", {
              detail: { error: err, scene: this.name, node: object.name },
            });
            document.dispatchEvent(event);
          });
        }
        object.src = src;
        if (obj.x) {
          object.x = obj.x;
        } else if (obj.transform) {
          object.x = obj.transform.position.x;
        }
        if (obj.y) {
          object.y = obj.y;
        } else if (obj.transform) {
          object.y = obj.transform.position.y;
        }
        if (obj.width || obj._width) {
          object.width = obj.width || obj._width;
        }
        if (obj.height || obj._height) {
          object.height = obj.height || obj._height;
        }

        object.visible = obj.visible;
        object.name = obj.name;

        object.tint =
          obj.tint !== null && obj.tint !== undefined ? obj.tint : 0xffffff;
        if (object.tint === null || object.tint === undefined) {
          object.tint =
            obj._tint !== null && obj._tint !== undefined
              ? obj._tint
              : 0xffffff;
        }
        object.type = "IMAGE";
        object.uuid = obj.uuid;
        parent.addChild(object);
        break;
      case "VIDEO":
        object = new PIXI.Sprite();
        let video_src =
          window.projectServerURL +
          "assets/videos/" +
          prefix +
          id +
          "/" +
          obj.src?.substring((obj.src || "").lastIndexOf("/") + 1);
        object.texture = PIXI.Texture.from(video_src);

        object.src = video_src;
        object.video_src = video_src;
        object.texture.baseTexture.resource.source.loop = obj.loop;
        object.texture.baseTexture.resource.source.preload = "auto";
        object.texture.baseTexture.resource.source.muted = true;
        object.texture.baseTexture.resource.source.autoplay = false;
        object.texture.baseTexture.resource.autoPlay = false;

        object.src = obj.src;

        if (obj.x) {
          object.x = obj.x;
        } else if (obj.transform) {
          object.x = obj.transform.position.x;
        }
        if (obj.y) {
          object.y = obj.y;
        } else if (obj.transform) {
          object.y = obj.transform.position.y;
        }
        if (obj.scale) {
          object.scale.x = obj.scale.x;
          object.scale.y = obj.scale.y;
        }

        if (obj.width || obj._width) {
          object.width = obj.width || obj._width;
        }
        if (obj.height || obj._height) {
          object.height = obj.height || obj._height;
        }
        object.loop = obj.loop;
        object.type = "VIDEO";
        object.name = obj.name;
        parent.addChild(object);
        break;
      case "AUDIO":
        object = new PIXI.Sprite();
        let audio_src =
          window.projectServerURL +
          "assets/audio/" +
          prefix +
          id +
          "/" +
          obj.src?.substring((obj.src || "").lastIndexOf("/") + 1);

        object.src = audio_src;
        object.audio_src = audio_src;

        object.type = "AUDIO";
        object.name = obj.name;
        object.loop = obj.loop;
        try {
          object.audio_player = document.createElement("audio");
          object.audio_player.src = object.src;
          object.audio_player.loop = object.loop;
          object.audio_player.load();
        } catch (err) {
          console.log(err);
        }

        try {
          if (window.SingularOverlayControlAudioHandler) {
            var mediaId = obj.uuid;
            var loadCmd = new Object();
            loadCmd.id = mediaId;
            loadCmd.command = "load";
            loadCmd.src = object.src;
            console.log(loadCmd);
            window.SingularOverlayControlAudioHandler(loadCmd);
          }
        } catch (err) {
          console.log(err);
        }
        try {
          if (window.SingularOverlayControlAudioHandler) {
            let loopCmd = new Object();
            loopCmd.id = mediaId;
            loopCmd.command = "loop";
            loopCmd.value = object.loop;
            console.log(loopCmd);
            window.SingularOverlayControlAudioHandler(loopCmd);
          }
        } catch (err) {
          console.log(err);
        }

        parent.addChild(object);
        break;
      case "INPUT":
        object = this.addInput({ parent });
        if (obj.width || obj._width) {
          object.width = obj.width || obj._width;
        }
        if (obj.height || obj._height) {
          object.height = obj.height || obj._height;
        }
        if (obj.x) {
          object.x = obj.x;
        } else if (obj.transform) {
          object.x = obj.transform.position.x;
        }
        if (obj.y) {
          object.y = obj.y;
        } else if (obj.transform) {
          object.y = obj.transform.position.y;
        }
        if (obj.scale) {
          object.scale.x = obj.scale.x;
          object.scale.y = obj.scale.y;
        }
        break;
      case "HEATMAP":
        let heatmap = this.addHeatMap({ addToScene: false });

        heatmap.name = obj.name;
        heatmap.heat.data([
          [27.342000000000002, 87.892, 1],
          [16.169999999999998, 98.556, 1],
          [8.232, 99.588, 1],
          [18.522, 103.372, 1],
          [5.292, 77.744, 1],
          [9.702, 106.29599999999999, 1],
          [41.16, 70.348, 1],
          [27.342000000000002, 66.22, 1],
          [26.753999999999998, 85.312, 1],
          [20.58, 89.95599999999999, 1],
          [21.168, 86, 1],
          [53.507999999999996, 78.088, 1],
          [19.698, 88.752, 1],
          [18.522, 94.772, 1],
          [37.337999999999994, 85.48400000000001, 1],
          [8.82, 79.292, 1],
          [51.744, 82.732, 1],
          [8.82, 81.872, 1],
          [25.872, 88.06400000000001, 1],
          [54.39, 65.01599999999999, 1],
          [8.526, 104.232, 1],
          [5.292, 77.744, 1],
          [22.932, 85.48400000000001, 1],
          [6.762, 50.052, 1],
          [37.337999999999994, 90.3, 1],
          [11.171999999999999, 87.20400000000001, 1],
          [27.93, 74.476, 1],
          [40.572, 78.776, 1],
          [21.756, 88.408, 1],
          [21.756, 78.26, 1],
          [10.29, 58.48, 1],
          [23.226, 80.84, 1],
          [8.82, 84.79599999999999, 1],
          [22.05, 83.42, 1],
          [9.114, 76.53999999999999, 1],
          [67.326, 101.996, 1],
          [68.208, 103.716, 1],
        ]);

        heatmap.heat.draw();

        heatmap.texture.update();
        if (obj.x) {
          heatmap.x = obj.x;
        } else if (obj.transform) {
          heatmap.x = obj.transform.position.x;
        }
        if (obj.y) {
          heatmap.y = obj.y;
        } else if (obj.transform) {
          heatmap.y = obj.transform.position.y;
        }
        heatmap.visible = obj.visible;

        heatmap.width = obj.width;
        heatmap.height = obj.height;

        parent.addChild(heatmap);
        break;
      default:
        break;
    }

    if (object) {
      if (obj.size_to_fit_padding) {
        object.size_to_fit_padding = obj.size_to_fit_padding;
      }

      if (obj.size_to_fit) {
        object.size_to_fit = obj.size_to_fit;
      }

      object.code = obj.code;

      if (object.code && object.code !== "") {
        try {
          // eslint-disable-next-line no-new-func
          let func = new Function(
            "hyperAPI",
            "currentItem",
            `
              let window = '';

              function onUpdateAnim() {

              }
              function onBeforeValueChange() {

              }
              function update() {

              }
              function loaded() {

              }
       
               ${object.code}
                return {
                  update: update,
                  loaded: loaded,
                  onUpdateAnim: onUpdateAnim,
                  onBeforeValueChange: onBeforeValueChange,
                };
              `
          )(window.hyperAPI, object);

          object.codeRunner = func;
        } catch (err) {
          console.log("Error in script", this.name, object.name, err);
        }
      }

      object.type = obj.type;
      object.maskID = obj.mask;
      object.alpha_mask = obj.alpha_mask;
      object.maxWidth = obj.maxWidth;
      if (obj.uuid === undefined || newID || this.ids.indexOf(obj.uuid) > -1) {
        console.log("changing UUID");
        object.uuid = uuid();
      } else {
        object.uuid = obj.uuid;
      }
      if (this.ids === undefined) {
        this.ids = [];
      }
      this.ids.push(object.uuid);

      if (obj.anchor) {
        object.anchor = obj.anchor;
      } else if (obj._anchor) {
        object.anchor = obj._anchor;
      } else {
        object.anchor = {
          x: 0,
          y: 0,
        };
      }

      if (obj.alpha) {
        object.alpha = obj.alpha;
      }
      if (obj.rotation) {
        object.rotation = obj.rotation;
      }

      if (obj.blendMode) {
        object.blendMode = obj.blendMode;
      }

      if (obj.skew) {
        object.skew.x = parseFloat(obj.skew.x);
        object.skew.y = parseFloat(obj.skew.y);
      }

      if (obj.dataBinds) {
        object.dataBinds = obj.dataBinds;
      }

      if (obj.control) {
        object.control = obj.control;
      } else {
        object.control = {
          id: 0,
          name: "",
          editables: [],
        };
      }
      object.filters = [];
      object.drop_shadow = obj.drop_shadow || {
        enabled: false,
        offset: { x: 4, y: 4 },
      };

      if (obj.autoFollow) {
        object.autoFollow = obj.autoFollow;
        object.autoFollowPadding = obj.autoFollowPadding;
        try {
          let func = new Function(
            "hyperAPI",
            "currentItem",
            `
              let window = '';
       
               function update() {
             
                if (currentItem.autoFollow) {
                 if (currentItem.autoFollow.type === 'TEXT') {
                   if (currentItem.autoFollow.text !== '' && currentItem.autoFollow.visible === true) {
                    hyperAPI.setProperty(currentItem, 'position.x', parseInt(currentItem.autoFollow.position.x) + parseInt(currentItem.autoFollow._width) + parseInt(currentItem.autoFollowPadding))
                   }else {
                    hyperAPI.setProperty(currentItem, 'position.x', parseInt(currentItem.autoFollow.position.x) + parseInt(currentItem.autoFollow._width))
                   }
                   
                 }  else  {
                   hyperAPI.setProperty(currentItem, 'position.x', parseInt(currentItem.autoFollow.position.x) + parseInt(currentItem.autoFollow.width) + parseInt(currentItem.autoFollowPadding))
                } 
              }
               }
                return {
                  update: update
                };
              `
          )(window.hyperAPI, object);

          object.autofollowCode = func;
        } catch (err) {}
      }
      if (importAnimations && obj.animations) {
        this.importAnimations(object, obj.animations);
        delete obj.animations;
      }
      for (let i = 0; i < obj.children.length; i++) {
        this.importObject(
          { ...obj.children[i] },
          object,
          newID,
          importAnimations
        );
      }
      if (object.updateAnchor) {
        object.updateAnchor();
      }
    }
  }

  loadMasks(items) {
    for (let i = 0; i < items.children.length; i++) {
      let item = items.children[i];

      if (item.maskID) {
        item.mask = this.getItemByUUID(item.maskID, this.scene.children);
      } else if (item.alpha_mask) {
        if (!item.filters) {
          item.filters = [];
        }

        item.filters.push(
          new SpriteMaskFilter_Alpha(
            this.getItemByUUID(item.alpha_mask, this.scene.children)
          )
        );
      }
      if (item.children.length > 0) {
        this.loadMasks(item);
      }
    }
  }

  string2hex(string) {
    if (typeof string === "string" && string[0] === "#") {
      string = string.substr(1);
    }

    return parseInt(string, 16);
  }

  loadDropShadows(items) {
    for (let i = 0; i < items.children.length; i++) {
      let item = items.children[i];

      if (item.drop_shadow?.enabled) {
        if (!item.filters) {
          item.filters = [];
        }
        let colour = "#ffffff";
        try {
          colour = this.string2hex(item.drop_shadow?.colour || "#ffffff");
        } catch (err) {}

        let new_filter = new DropShadowFilter({
          ...item.drop_shadow,
          color: colour,
        });
        new_filter.name = "DROPSHADOW";
        item.filters.push(new_filter);
      }
      if (item.children.length > 0) {
        this.loadDropShadows(item);
      }
    }
  }

  loadSizeToFit(items) {
    for (let i = 0; i < items.children.length; i++) {
      let item = items.children[i];

      if (item.size_to_fit) {
        item.size_to_fit = item.size_to_fit?.map((i) => {
          return this.getItemByUUID(i, this.scene.children);
        });
      }
      if (item.children.length > 0) {
        this.loadSizeToFit(item);
      }
    }
  }

  loadAutoFollow(items) {
    for (let i = 0; i < items.children.length; i++) {
      let item = items.children[i];

      if (item.autoFollow) {
        item.autoFollow = this.getItemByUUID(
          item.autoFollow,
          this.scene.children
        );
      }
      if (item.children.length > 0) {
        this.loadAutoFollow(item);
      }
    }
  }

  getItemByUUID(uuid, children) {
    for (let i = 0; i < children.length; i++) {
      let item = children[i];
      if (item.uuid === uuid) {
        return item;
      } else if (item.children.length > 0) {
        let child = this.getItemByUUID(uuid, item.children);
        if (child) {
          return child;
        }
      }
    }
  }

  getItemByName(name, children) {
    for (let i = 0; i < children.length; i++) {
      let item = children[i];
      if (item.name === name) {
        return item;
      } else if (item.children.length > 0) {
        let child = this.getItemByName(name, item.children);
        if (child) {
          return child;
        }
      }
    }
  }

  getItemsByType({ type, children, items_array = [] }) {
    let newChildren = children.filter((c) => c.type === type);
    items_array = [...items_array, ...newChildren];
    let childs = children.filter((c) => c.children && c.children.length > 0);
    for (let i = 0; i < childs.length; i++) {
      items_array = this.getItemsByType({
        type,
        children: childs[i].children,
        items_array,
      });
    }

    return items_array;
  }

  getControls({ children, control_array = [] }) {
    let newChildren = children.filter(
      (c) => c.control && c.control.name !== ""
    );
    control_array = [...control_array, ...newChildren];
    let childs = children.filter((c) => c.children && c.children.length > 0);
    for (let i = 0; i < childs.length; i++) {
      control_array = this.getControls({
        children: childs[i].children,
        control_array,
      });
    }

    return control_array;
  }

  getItemsByDataName({
    name,
    children,
    control_array = [],
    template,
    app = "football",
  }) {
    let newChildren = children?.filter((c) => {
      let item;
      if (app === "football") {
        item = c.control?.data?.variables?.find(
          (v) => v.template === template && v.field === name
        );
      } else {
        item = c.control?.data?.[app]?.variables?.find(
          (v) => v.template === template && v.field === name
        );
      }
      return c.control && c.control.name !== "" && item;
    });

    control_array = [...control_array, ...newChildren];
    let childs = children.filter((c) => c.children && c.children.length > 0);
    for (let i = 0; i < childs.length; i++) {
      control_array = this.getItemsByDataName({
        name,
        children: childs[i].children,
        control_array,
        template,
        app,
      });
    }

    return control_array;
  }

  preload() {
    this.loadItem(this.scene);
  }

  loadItem(items) {
    for (let i = 0; i < items.children.length; i++) {
      let item = items.children[i];

      try {
        if (item._textures) {
          for (let i = 0; i < item._textures.length; i++) {
            if (
              !item._textures[i].baseTexture._glTextures[0] &&
              window.preview === false &&
              (item._textures[i].baseTexture.garbage_collect === undefined ||
                item._textures[i].baseTexture.garbage_collect === true)
            ) {
              console.log("binded: " + item._textures[i]);
              // window.renderer.texture.bind(item._textures[i]);
              // item._textures[i].garbage_collect = false;
              // console.log(item._textures[i]);
            }
          }
        }
      } catch (e) {
        console.log("---ERROR Scene:644--");
        console.log(e);
      }

      if (item.children.length > 0) {
        this.loadItem(item);
      }
    }
  }
}

export default Scene;
