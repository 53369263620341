import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../../Theme/Hyper";
const Select = styled.select`
  background-color: #181d25;
  color: #fff;
  padding: 4px;
  border: none;
  margin: 4px;
  border-radius: 4px;
  max-width: 100px;
`;
const Main = styled.div`
  padding: 0.5em;
  width: 440px;
  height: calc(100vh - 85px);
  background-color: #181d25;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  background-color: #1f252f;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -20px rgba(0, 0, 0, 0.2),
    0px 5px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
`;

const Title = styled.div`
  font-size: 1.1em;
  background-color: #282f3c;
  padding: 0.3em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #161a21;
`;

const Row = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
`;

const VariablesTable = styled.div`
  margin-top: 10px;
`;
const Column = styled.div`
  display: flex;
  width: 25%;
`;
const Input = styled.input`
  border: 1px solid #232531;
  padding: 4px;
  font-size: 0.9em;
  background-color: #12161d;
  border-radius: 3px;
  color: #fff;
  max-width: 100px;
`;

const home_team = [
  { name: "Home Team Code" },
  { name: "Home Team Name" },
  { name: "Home Team Short Name" },
  { name: "Home Team Badge" },
  { name: "Home Team Secondary Badge" },
  { name: "Home Team Video Badge" },
  { name: "Home Team Kit BG Colour", type: "colour" },
  { name: "Home Team Kit Text Colour", type: "colour" },
];
const away_team = [
  { name: "Away Team Code" },
  { name: "Away Team Name" },
  { name: "Away Team Short Name" },
  { name: "Away Team Badge" },
  { name: "Away Team Secondary Badge" },
  { name: "Away Team Video Badge" },
  { name: "Away Team Kit BG Colour", type: "colour" },
  { name: "Away Team Kit Text Colour", type: "colour" },
];
const competition = [
  { name: "Competition" },
  { name: "Competition Badge" },
  { name: "Competition Colour" },
];
const match = [
  { name: "Date" },
  { name: "Stadium" },
  { name: "Kick-Off" },
  { name: "Home Score" },
  { name: "Away Score" },
  { name: "Home Agg Score" },
  { name: "Away Agg Score" },
  { name: "Leg" },
  { name: "Period" },
  { name: "Group" },
  { name: "Game Clock" },
  { name: "Additional Time" },
  { name: "Home Red Cards" },
  { name: "Away Red Cards" },
  { name: "Attendance" },
];

const team = (index) => {
  if (index) {
    return [
      { name: "Team Code " + index },
      { name: "Team Name " + index },
      { name: "Team Short Name " + index },
      { name: "Team Badge " + index },
      { name: "Team Secondary Badge " + index },
      { name: "Team Video Badge " + index },
      { name: "Team Kit BG Colour " + index, type: "colour" },
      { name: "Team Kit Text Colour " + index, type: "colour" },
    ];
  } else {
    return [
      { name: "Team Code" },
      { name: "Team Name" },
      { name: "Team Short Name" },
      { name: "Team Badge" },
      { name: "Team Secondary Badge" },
      { name: "Team Video Badge" },
      { name: "Team Kit BG Colour", type: "colour" },
      { name: "Team Kit Text Colour", type: "colour" },
    ];
  }
};
const commentator = (index) => {
  if (index) {
    return [
      { name: "Commentator First Name " + index },
      { name: "Commentator Last Name " + index },
      { name: "Commentator First Name Uppercase " + index },
      { name: "Commentator Last Name Uppercase " + index },
      { name: "Commentator Full Name " + index },
      { name: "Commentator Full Name Uppercase " + index },
    ];
  } else {
    return [
      { name: "Commentator First Name" },
      { name: "Commentator Last Name" },
      { name: "Commentator First Name Uppercase" },
      { name: "Commentator Last Name Uppercase" },
      { name: "Commentator Full Name" },
      { name: "Commentator Full Name Uppercase" },
    ];
  }
};
const team_standings = (index) => {
  return [
    ...team(index),
    { name: "Team Games Played " + index },
    { name: "Team Games Won " + index },
    { name: "Team Games Lost " + index },
    { name: "Team Games Drawn " + index },
    { name: "Team Points " + index },
    { name: "Team Goal Difference " + index },
    { name: "Team Goals For " + index },
    { name: "Team Goals Against " + index },
    { name: "Team Rank " + index },
    { name: "Team Rank Status " + index },
  ];
};
const Manager = [
  { name: "Manager First Name" },
  { name: "Manager Last Name" },
  { name: "Manager First Name Uppercase" },
  { name: "Manager Last Name Uppercase" },
  { name: "Manager Full Name" },
  { name: "Manager Full Name Uppercase" },
  { name: "Manager Nationality" },
  { name: "Manager Title" },
];
const player = (index) => {
  if (index) {
    return [
      { name: "First Name " + index },
      { name: "Last Name " + index },
      { name: "First Name Uppercase " + index },
      { name: "Last Name Uppercase " + index },
      { name: "Full Name " + index },
      { name: "Full Name Uppercase " + index },
      { name: "Nationality " + index },
      { name: "Position " + index },
      { name: "Shirt Number " + index },
      { name: "Captain " + index },
      { name: "X/Y " + index },
      { name: "Subbed " + index },
      { name: "Card " + index },
      { name: "Goals " + index },
    ];
  } else {
    return [
      { name: "Title" },
      { name: "First Name" },
      { name: "Last Name" },
      { name: "First Name Uppercase" },
      { name: "Last Name Uppercase" },
      { name: "Full Name" },
      { name: "Full Name Uppercase" },
      { name: "Nationality" },
      { name: "Position" },
      { name: "Shirt Number" },
      { name: "Captain " },
      { name: "Subbed" },
      { name: "Card" },
      { name: "Goals" },
    ];
  }
};
const team_stats = (team) => {
  return [
    { name: team + " Possession" },
    { name: team + " Total Shots" },
    { name: team + " On Target" },
    { name: team + " Clear Cut Chances" },
    { name: team + " Successful Crosses" },
    { name: team + " Successful Dribbles" },
    { name: team + " Corners" },
    { name: team + " Offsides" },
    { name: team + " Yellow Cards" },
    { name: team + " Tackles Won" },
    { name: team + " Interceptions" },
    { name: team + " Blocks" },
    { name: team + " Clearances" },
    { name: team + " Headed Clearances" },
    { name: team + " Aerial Duels Won" },
    { name: team + " Blocked Crosses" },
    { name: team + " Passing Accuracy" },
    { name: team + " Attacking 3rd" },
    { name: team + " Key Passes" },
    { name: team + " Fouls Committed" },
    { name: team + " Fouls Won" },
    { name: team + " Yellow Cards" },
    { name: team + " Red Cards" },
    { name: team + " Touches In Opp Box" },
  ];
};

const penalty = (team, index) => {
  return { name: team + " Penalty " + index };
};
const commentators = (index) => {
  return [
    { name: "Comm First Name " + index },
    { name: "Comm Last Name " + index },
    { name: "Comm Full Name " + index },
  ];
};

const getScorer = (team, index) => {
  return [
    { name: team + " Scorer " + index + " Shirt Number" },
    { name: team + " Scorer " + index + " First Name Uppercase" },
    { name: team + " Scorer " + index + " Last Name Uppercase" },
    { name: team + " Scorer " + index + " Goal Time" },
  ];
};

const game = (index) => {
  return [
    { name: "Game " + index + " Home Team Name" },
    { name: "Game " + index + " Home Team Short Name" },
    { name: "Game " + index + " Home Team Badge" },
    { name: "Game " + index + " Home Team Score" },
    { name: "Game " + index + " Away Team Name" },
    { name: "Game " + index + " Away Team Short Name" },
    { name: "Game " + index + " Away Team Badge" },
    { name: "Game " + index + " Away Team Score" },
    { name: "Game " + index + " Status" },
    { name: "Game " + index + " Date" },
    { name: "Game " + index + " Kick Off" },
    { name: "Game " + index + " Attendance" },
  ];
};
const zone = (i) => {
  return [
    { name: "Zone Value " + i },
    { name: "Zone Colour " + i },
    { name: "Zone Team " + i },
  ];
};

const home_scorers = [
  { name: "Home Scorers Line 1" },
  { name: "Home Scorers Line 2" },
  { name: "Home Scorers Line 3" },
];

const away_scorers = [
  { name: "Away Scorers Line 1" },
  { name: "Away Scorers Line 2" },
  { name: "Away Scorers Line 3" },
];

const graphics = [
  {
    name: "Versus",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      ...commentators(1),
      ...commentators(2),
      ...commentators(3),
      { name: "Group" },
    ],
  },
  {
    name: "MatchID",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      { name: "Show Background" },
    ],
  },
  {
    name: "FFCustomSlate",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      { name: "Caption" },
    ],
  },
  {
    name: "FFWeatherSlate",
    variables: [...home_team, ...away_team, ...competition, ...match],
  },
  {
    name: "FFTechnicalSlate",
    variables: [...home_team, ...away_team, ...competition, ...match],
  },
  {
    name: "FFPostponedSlate",
    variables: [...home_team, ...away_team, ...competition, ...match],
  },
  {
    name: "FFCancelledSlate",
    variables: [...home_team, ...away_team, ...competition, ...match],
  },

  {
    name: "Score Update",
    variables: [...home_team, ...away_team, ...competition, ...match],
  },

  {
    name: "Clock",
    variables: [...home_team, ...away_team, ...competition, ...match],
  },

  {
    name: "Name",
    variables: [...competition, ...player(), ...team(), { name: "Subtitle" }],
  },

  {
    name: "Formation",
    variables: [
      { name: "Team Type" },
      ...team(),
      ...Manager,
      ...competition,
      ...match,
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...player(7),
      ...player(8),
      ...player(9),
      ...player(10),
      ...player(11),
      ...player(12),
      ...player(13),
      ...player(14),
      ...player(15),
      ...player(16),
      ...player(17),
      ...player(18),
      ...player(19),
      ...player(20),
      ...player(21),
      ...player(22),
      ...player(23),
      ...player(24),
      ...player(25),
      ...player(26),
      ...player(27),
      ...player(28),
      ...player(29),
      ...player(30),
      { name: "Sponsor Image" },
    ],
  },

  {
    name: "FF Stats",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      ...team_stats("Home"),
      ...team_stats("Away"),
    ],
  },

  {
    name: "Attendance",
    variables: [...competition, ...match],
  },
  {
    name: "Commentators",
    variables: [
      ...competition,
      ...match,
      ...commentator(1),
      ...commentator(2),
      ...commentator(3),
    ],
  },
];

export default function NetballData({ scene, app }) {
  const [update, setUpdate] = useState();
  return (
    <Main>
      <Group>
        <Title>Graphic Template</Title>
        {new Array(scene.netball?.templates?.length || 0)
          .fill(1)
          .map((_, index) => {
            let template = scene.netball.templates?.[index] || graphics[0];

            return (
              <>
                <Button
                  style={{ fontSize: "15px" }}
                  onClick={() => {
                    scene.netball.templates = scene.netball.templates.filter(
                      (t) => {
                        return t.name !== template.name;
                      }
                    );
                    setUpdate(Date.now());
                  }}
                >
                  Remove Template
                </Button>
                <TemplateEditor
                  template={template}
                  scene={scene}
                  app={app}
                  template_index={index}
                  setUpdate={setUpdate}
                  update={update}
                />
              </>
            );
          })}
      </Group>
      <Group
        onClick={() => {
          if (!scene.netball?.templates?.length) {
            scene.netball.templates = [];
          }
          scene.netball.templates.push({ name: graphics[0].name });
          setUpdate(Date.now());
        }}
      >
        <Title>+ Add Template</Title>
      </Group>
    </Main>
  );
}

function TemplateEditor({
  template,
  scene,
  app,
  template_index,
  setUpdate,
  update,
}) {
  const [selectedTemplate, setSelectedTemplate] = useState(
    graphics.find((g) => g.name === template?.name)
  );
  const [nodes, setNodes] = useState([]);
  const [showInput, setShowInput] = useState(false);
  useEffect(() => {
    setNodes(
      getControls({ children: scene.scene.children, control_array: [] })
    );

    setSelectedTemplate(graphics.find((g) => g.name === template.name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene, template]);

  function getControls({ children, control_array }) {
    let newChildren = children.filter(
      (c) => c.control && c.control.name !== ""
    );
    control_array = [...control_array, ...newChildren];
    let childs = children.filter((c) => c.children && c.children.length > 0);
    for (let i = 0; i < childs.length; i++) {
      control_array = getControls({
        children: childs[i].children,
        control_array,
      });
    }

    return control_array;
  }

  return (
    <>
      <Select
        style={{ maxWidth: "200px" }}
        onChange={(event) => {
          if (!scene.netball) {
            scene.netball = {};
          }

          template = event.currentTarget.value;
          scene.netball.templates[template_index] = {
            ...scene.netball.templates[template_index],
            name: template,
          };
          app.setState(app.state);

          setSelectedTemplate(
            graphics.find((graphic) => graphic.name === template)
          );
          setUpdate(Date.now());
        }}
        value={selectedTemplate?.name}
      >
        {graphics.map((graphic, index) => {
          return <option value={graphic.name}>{graphic.name}</option>;
        })}
      </Select>
      {selectedTemplate && (
        <VariablesTable>
          <Row>
            <Column>Node</Column>
            <Column>Data variable</Column>
            <Column>Default value</Column>
            <Column></Column>
          </Row>
          {nodes?.map((node) => {
            return (
              <Row>
                <Column>{node?.control?.name || ""}</Column>
                <Column>
                  {showInput && (
                    <Input
                      value={
                        node?.control?.data?.variables?.find(
                          (v) => v.template === selectedTemplate.name
                        )?.field || ""
                      }
                      onChange={(ev) => {
                        let item = scene.getItemByUUID(
                          node.uuid,
                          scene.scene.children
                        );

                        if (!item.control?.data?.variables) {
                          item.control.data = { variables: [] };
                        }

                        let control = item.control.data.variables.find(
                          (v) => v.template === selectedTemplate.name
                        );

                        item.control.data.variables =
                          item.control.data.variables.filter(
                            (v) => v.template !== selectedTemplate.name
                          );

                        item.control.data.variables.push({
                          ...control,
                          template: selectedTemplate.name,
                          field: ev.currentTarget.value,
                        });

                        setUpdate(Date.now());
                      }}
                    />
                  )}
                  {!showInput && (
                    <Select
                      onChange={(ev) => {
                        let item = scene.getItemByUUID(
                          node.uuid,
                          scene.scene.children
                        );

                        if (!item.control?.data?.variables) {
                          item.control.data = { variables: [] };
                        }

                        let control = item.control.data.variables.find(
                          (v) => v.template === selectedTemplate.name
                        );

                        item.control.data.variables =
                          item.control.data.variables.filter(
                            (v) => v.template !== selectedTemplate.name
                          );

                        item.control.data.variables.push({
                          ...control,
                          template: selectedTemplate.name,
                          field: ev.currentTarget.value,
                        });

                        setUpdate(Date.now());
                      }}
                      value={
                        node?.control?.data?.variables?.find(
                          (v) => v.template === selectedTemplate.name
                        )?.field || ""
                      }
                    >
                      <option value="">---</option>
                      {selectedTemplate?.variables?.map((variable, index) => {
                        return (
                          <option value={variable.name}>{variable.name}</option>
                        );
                      })}
                    </Select>
                  )}
                </Column>
                <Column>
                  <Input
                    value={
                      node?.control?.data?.variables?.find(
                        (v) => v.template === selectedTemplate.name
                      )?.default
                    }
                    onChange={(e) => {
                      let item = scene.getItemByUUID(
                        node.uuid,
                        scene.scene.children
                      );

                      if (!item.control?.data?.variables) {
                        item.control.data = { variables: [] };
                      }
                      let index = item.control.data.variables.findIndex(
                        (v) => v.template === selectedTemplate.name
                      );
                      if (index === -1) {
                        item.control.data.variables.push({
                          template: selectedTemplate.name,
                          default: e.currentTarget.value,
                        });
                      } else {
                        item.control.data.variables[index].default =
                          e.currentTarget.value;
                      }

                      setUpdate(Date.now());
                    }}
                  />
                </Column>
                <Column>
                  <Input
                    type="checkbox"
                    checked={
                      node?.control?.data?.variables?.find(
                        (v) => v.template === selectedTemplate.name
                      )?.hide
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      let item = scene.getItemByUUID(
                        node.uuid,
                        scene.scene.children
                      );

                      if (!item.control?.data?.variables) {
                        item.control.data = { variables: [] };
                      }
                      let index = item.control.data.variables.findIndex(
                        (v) => v.template === selectedTemplate.name
                      );
                      if (index === -1) {
                        item.control.data.variables.push({
                          template: selectedTemplate.name,
                          hide: e.currentTarget.checked,
                        });
                      } else {
                        item.control.data.variables[index].hide =
                          e.currentTarget.checked;
                      }

                      setUpdate(Date.now());
                    }}
                  />
                </Column>
              </Row>
            );
          })}
        </VariablesTable>
      )}
    </>
  );
}
