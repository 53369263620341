import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../../Theme/Hyper";
const Select = styled.select`
  background-color: #181d25;
  color: #fff;
  padding: 4px;
  border: none;
  margin: 4px;
  border-radius: 4px;
`;
const Main = styled.div`
  padding: 0.5em;
  width: 440px;
  height: calc(100vh - 85px);
  background-color: #181d25;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  background-color: #1f252f;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -20px rgba(0, 0, 0, 0.2),
    0px 5px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
`;
const Input = styled.input`
  border: 1px solid #232531;
  padding: 4px;
  font-size: 0.9em;
  background-color: #12161d;
  border-radius: 3px;
  color: #fff;
  max-width: 100px;
`;
const Title = styled.div`
  font-size: 1.1em;
  background-color: #282f3c;
  padding: 0.3em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #161a21;
`;

const Row = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
`;

const VariablesTable = styled.div`
  margin-top: 10px;
`;
const Column = styled.div`
  display: flex;
  width: 50%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #424242;
`;

const home_team = [
  { name: "Home Team Code" },
  { name: "Home Team Name" },
  { name: "Home Team Badge" },
  { name: "Home Team Secondary Badge" },
  { name: "Home Team Third Badge" },
  { name: "Home Team Video Badge" },
  { name: "Home Team Kit BG Colour", type: "colour" },
  { name: "Home Team Kit Text Colour", type: "colour" },
  { name: "Home Team Kit Pattern Colour", type: "colour" },
  { name: "Home Team Kit BG Colour String" },
  { name: "Home Team Kit Text Colour String" },
  { name: "Home Team Kit Pattern Colour String" },
];
const away_team = [
  { name: "Away Team Code" },
  { name: "Away Team Name" },
  { name: "Away Team Badge" },
  { name: "Away Team Secondary Badge" },
  { name: "Away Team Third Badge" },
  { name: "Away Team Video Badge" },
  { name: "Away Team Kit BG Colour", type: "colour" },
  { name: "Away Team Kit Text Colour", type: "colour" },
  { name: "Away Team Kit Pattern Colour", type: "colour" },
  { name: "Away Team Kit BG Colour String" },
  { name: "Away Team Kit Text Colour String" },
  { name: "Away Team Kit Pattern Colour String" },
];
const competition = [
  { name: "Competition" },
  { name: "Competition Badge" },
  { name: "Competition Secondary Badge" },
  { name: "Competition Type" },
];
const match = (index) => {
  if (index) {
    return [
      { name: "Game " + index + " Home Team Code" },
      { name: "Game " + index + " Home Team Name" },
      { name: "Game " + index + " Home Team Short Name" },
      { name: "Game " + index + " Home Team Badge" },
      { name: "Game " + index + " Home Team Secondary Badge" },
      { name: "Game " + index + " Home Team Third Badge" },
      { name: "Game " + index + " Home Team Video Badge" },
      { name: "Game " + index + " Home Team Kit BG Colour", type: "colour" },
      { name: "Game " + index + " Home Team Kit Text Colour", type: "colour" },
      {
        name: "Game " + index + " Home Team Kit Pattern Colour",
        type: "colour",
      },
      { name: "Game " + index + " Home Team Kit BG Colour String" },
      { name: "Game " + index + " Home Team Kit Text Colour String" },
      { name: "Game " + index + " Home Team Kit Pattern Colour String" },
      { name: "Game " + index + " Away Team Code" },
      { name: "Game " + index + " Away Team Name" },
      { name: "Game " + index + " Away Team Short Name" },
      { name: "Game " + index + " Away Team Badge" },
      { name: "Game " + index + " Away Team Secondary Badge" },
      { name: "Game " + index + " Away Team Third Badge" },
      { name: "Game " + index + " Away Team Video Badge" },
      { name: "Game " + index + " Away Team Kit BG Colour", type: "colour" },
      { name: "Game " + index + " Away Team Kit Text Colour", type: "colour" },
      {
        name: "Game " + index + " Away Team Kit Pattern Colour",
        type: "colour",
      },
      { name: "Game " + index + " Away Team Kit BG Colour String" },
      { name: "Game " + index + " Away Team Kit Text Colour String" },
      { name: "Game " + index + " Away Team Kit Pattern Colour String" },
      { name: "Game " + index + " Location" },
      { name: "Game " + index + " Stadium" },
      { name: "Game " + index + " Date" },
      { name: "Game " + index + " Kick-Off" },
      { name: "Game " + index + " Home Score" },
      { name: "Game " + index + " Away Score" },
      { name: "Game " + index + " Period" },
      { name: "Game " + index + " Status" },
      { name: "Game " + index + " Game Clock" },
      { name: "Game " + index + " Additional Time" },
      { name: "Game " + index + " Home Red Cards" },
      { name: "Game " + index + " Away Red Cards" },
      { name: "Game " + index + " Home Yellow Cards" },
      { name: "Game " + index + " Away Yellow Cards" },
      { name: "Game " + index + " Phase" },
      { name: "Game " + index + " Metres Gained" },
      { name: "Game " + index + " Home Tries" },
      { name: "Game " + index + " Home Convs" },
      { name: "Game " + index + " Home Pen Kicks" },
      { name: "Game " + index + " Home Pen Tries" },
      { name: "Game " + index + " Home Drop Goals" },
      { name: "Game " + index + " Home Total Tries" },
      { name: "Game " + index + " Away Tries" },
      { name: "Game " + index + " Away Convs" },
      { name: "Game " + index + " Away Pen Kicks" },
      { name: "Game " + index + " Away Pen Tries" },
      { name: "Game " + index + " Away Drop Goals" },
      { name: "Game " + index + " Away Total Tries" },
    ];
  } else {
    return [
      { name: "Location" },
      { name: "Stadium" },
      { name: "Date" },
      { name: "Kick-Off" },
      { name: "Home Score" },
      { name: "Away Score" },
      { name: "Period" },
      { name: "Game Clock" },
      { name: "Additional Time" },
      { name: "Home Red Cards" },
      { name: "Away Red Cards" },
      { name: "Home Yellow Cards" },
      { name: "Away Yellow Cards" },
      { name: "Phase" },
      { name: "Metres Gained" },
      { name: "Home Tries" },
      { name: "Home Convs" },
      { name: "Home Pen Kicks" },
      { name: "Home Pen Tries" },
      { name: "Home Drop Goals" },
      { name: "Home Total Tries" },
      { name: "Away Tries" },
      { name: "Away Convs" },
      { name: "Away Pen Kicks" },
      { name: "Away Pen Tries" },
      { name: "Away Drop Goals" },
      { name: "Away Total Tries" },
    ];
  }
};
const team = (index) => {
  if (index) {
    return [
      { name: "Team Code " + index },
      { name: "Team Name " + index },
      { name: "Team Short Name " + index },
      { name: "Team Badge " + index },
      { name: "Team Secondary Badge " + index },
      { name: "Team Third Badge " + index },
      { name: "Team Video Badge " + index },
      { name: "Team Kit BG Colour " + index, type: "colour" },
      { name: "Team Kit Text Colour " + index, type: "colour" },
      { name: "Team Kit Pattern Colour " + index, type: "colour" },
      { name: "Team Kit BG Colour String " + index },
      { name: "Team Kit Text Colour String " + index },
      { name: "Team Kit Pattern Colour String " + index },
    ];
  } else {
    return [
      { name: "Team Type" },
      { name: "Team Code" },
      { name: "Team Name" },
      { name: "Team Short Name" },
      { name: "Team Badge" },
      { name: "Team Secondary Badge" },
      { name: "Team Third Badge" },
      { name: "Team Video Badge" },
      { name: "Team Kit BG Colour", type: "colour" },
      { name: "Team Kit Text Colour", type: "colour" },
      { name: "Team Kit pattern Colour", type: "colour" },
      { name: "Team Kit BG Colour String" },
      { name: "Team Kit Text Colour String" },
      { name: "Team Kit Pattern Colour String" },
    ];
  }
};
const player = (index, team = "") => {
  if (index) {
    return [
      { name: team + "First Name " + index },
      { name: team + "Last Name " + index },
      { name: team + "First Name Uppercase " + index },
      { name: team + "Last Name Uppercase " + index },
      { name: team + "Short Name " + index },
      { name: team + "Short Name Uppercase " + index },
      { name: team + "Full Name " + index },
      { name: team + "Full Name Uppercase " + index },
      { name: team + "Nationality " + index },
      { name: team + "Position " + index },
      { name: team + "Shirt Number " + index },
      { name: team + "Captain " + index },
      { name: team + "Image " + index },
    ];
  } else {
    return [
      { name: team + "Title" },
      { name: team + "First Name" },
      { name: team + "Last Name" },
      { name: team + "First Name Uppercase" },
      { name: team + "Last Name Uppercase" },
      { name: team + "Short Name" },
      { name: team + "Short Name Uppercase" },
      { name: team + "Full Name" },
      { name: team + "Full Name Uppercase" },
      { name: team + "Nationality" },
      { name: team + "Position" },
      { name: team + "Shirt Number" },
      { name: team + "Captain" },
      { name: team + "Image" },
    ];
  }
};
const standings = (index) => {
  return [
    { name: "Position " + index },
    { name: "Played " + index },
    { name: "Won " + index },
    { name: "Lost " + index },
    { name: "Drawn " + index },
    { name: "Diff " + index },
    { name: "Points " + index },
    { name: "Highlight " + index },
  ];
};
const penalty = (team, index) => {
  return { name: team + " Penalty " + index };
};
const commentators = (index) => {
  return [
    { name: "Comm First Name " + index },
    { name: "Comm Last Name " + index },
    { name: "Comm Full Name " + index },
  ];
};
const official = (type) => {
  return [
    { name: type + " Official First Name" },
    { name: type + " Official Last Name" },
    { name: type + " Official Full Name" },
  ];
};
const Manager = [
  { name: "Manager First Name" },
  { name: "Manager Last Name" },
  { name: "Manager First Name Uppercase" },
  { name: "Manager Last Name Uppercase" },
  { name: "Manager Full Name" },
  { name: "Manager Nationality" },
  { name: "Manager Title" },
];
const Home_Manager = [
  { name: "Home Manager First Name" },
  { name: "Home Manager Last Name" },
  { name: "Home Manager First Name Uppercase" },
  { name: "Home Manager Last Name Uppercase" },
  { name: "Home Manager Full Name" },
  { name: "Home Manager Nationality" },
  { name: "Home Manager Title" },
];
const Away_Manager = [
  { name: "Away Manager First Name" },
  { name: "Away Manager Last Name" },
  { name: "Away Manager First Name Uppercase" },
  { name: "Away Manager Last Name Uppercase" },
  { name: "Away Manager Full Name" },
  { name: "Away Manager Nationality" },
  { name: "Away Manager Title" },
];
const graphics = [
  {
    name: "Versus",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match(),
      ...commentators(1),
      ...commentators(2),
      ...commentators(3),
    ],
  },
  {
    name: "MatchID",
    variables: [...home_team, ...away_team, ...competition, ...match()],
  },
  {
    name: "Half-Time Stats",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match(),
      { name: "Possession Home Value" },
      { name: "Completed Sets Home Value" },
      { name: "Completion Rate Home Value" },
      { name: "Tackles Made Home Value" },
      { name: "Tackles Missed Home Value" },
      { name: "Carries Home Value" },
      { name: "Metres Gained Home Value" },
      { name: "Handling Errors Home Value" },
      { name: "Possession Away Value" },
      { name: "Completed Sets Away Value" },
      { name: "Completion Rate Away Value" },
      { name: "Tackles Made Away Value" },
      { name: "Tackles Missed Away Value" },
      { name: "Carries Away Value" },
      { name: "Metres Gained Away Value" },
      { name: "Conversions Home Value" },
      { name: "Conversions Away Value" },
      { name: "Penalties Home Value" },
      { name: "Penalties Away Value" },
      { name: "Clean Breaks Home Value" },
      { name: "Clean Breaks Away Value" },
      { name: "Tackles Home Value" },
      { name: "Tackles Away Value" },
      { name: "Turnovers Conceded Home Value" },
      { name: "Turnovers Conceded Away Value" },
      { name: "Scrums Won Home Value" },
      { name: "Scrums Won Away Value" },
      { name: "Lineouts Won Home Value" },
      { name: "Lineouts Won Away Value" },
      { name: "Tackles Missed Home Value" },
      { name: "Tackles Missed Away Value" },
      { name: "Defenders Beaten Home Value" },
      { name: "Defenders Beaten Away Value" },
      { name: "Offloads Home Value" },
      { name: "Offloads Away Value" },
      { name: "Lineouts Lost Home Value" },
      { name: "Lineouts Lost Away Value" },
      { name: "Zone A Possession" },
      { name: "Zone B Possession" },
      { name: "Zone C Possession" },
      { name: "Zone D Possession" },
    ],
  },
  {
    name: "Full-Time Stats",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match(),
      { name: "Possession Home Value" },
      { name: "Completed Sets Home Value" },
      { name: "Completion Rate Home Value" },
      { name: "Tackles Made Home Value" },
      { name: "Tackles Missed Home Value" },
      { name: "Carries Home Value" },
      { name: "Metres Gained Home Value" },
      { name: "Handling Errors Home Value" },
      { name: "Possession Away Value" },
      { name: "Completed Sets Away Value" },
      { name: "Completion Rate Away Value" },
      { name: "Tackles Made Away Value" },
      { name: "Tackles Missed Away Value" },
      { name: "Carries Away Value" },
      { name: "Metres Gained Away Value" },
      { name: "Handling Errors Away Value" },
      { name: "Conversions Home Value" },
      { name: "Conversions Away Value" },
      { name: "Penalties Home Value" },
      { name: "Penalties Away Value" },
      { name: "Clean Breaks Home Value" },
      { name: "Clean Breaks Away Value" },
      { name: "Tackles Home Value" },
      { name: "Tackles Away Value" },
      { name: "Turnovers Conceded Home Value" },
      { name: "Turnovers Conceded Away Value" },
      { name: "Scrums Won Home Value" },
      { name: "Scrums Won Away Value" },
      { name: "Lineouts Won Home Value" },
      { name: "Lineouts Won Away Value" },
      { name: "Tackles Missed Home Value" },
      { name: "Tackles Missed Away Value" },
      { name: "Defenders Beaten Home Value" },
      { name: "Defenders Beaten Away Value" },
      { name: "Offloads Home Value" },
      { name: "Offloads Away Value" },
      { name: "Lineouts Lost Home Value" },
      { name: "Lineouts Lost Away Value" },
      { name: "Zone A Possession" },
      { name: "Zone B Possession" },
      { name: "Zone C Possession" },
      { name: "Zone D Possession" },
    ],
  },
  {
    name: "Score Update",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match(),
      { name: "Last Goal Player First Name Uppercase" },
      { name: "Last Goal Player Last Name Uppercase" },
      { name: "Last Goal Team" },
      { name: "Last Goal Type" },
      { name: "Last Goal Time" },
    ],
  },
  {
    name: "FF Score",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match(),
      { name: "Last Goal Player First Name Uppercase" },
      { name: "Last Goal Player Last Name Uppercase" },
      { name: "Last Goal Team" },
      { name: "Last Goal Type" },
      { name: "Last Goal Time" },
      { name: "Home Try Scorers" },
      { name: "Away Try Scorers" },
      { name: "Home Conversion Scorers" },
      { name: "Away Conversion Scorers" },
      { name: "Home Penalty Scorers" },
      { name: "Away Penalty Scorers" },
    ],
  },
  {
    name: "HT Score",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match(),
      { name: "Half Text" },
    ],
  },
  {
    name: "FT Score",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match(),
      { name: "Half Text" },
    ],
  },
  {
    name: "ET HT Score",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match(),
      { name: "Half Text" },
    ],
  },
  {
    name: "ET FT Score",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match(),
      { name: "Half Text" },
    ],
  },
  {
    name: "Penalties Score",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match(),
      { name: "Half Text" },
    ],
  },
  {
    name: "Team Stat",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match(),
      { name: "Category" },
      { name: "Home Value" },
      { name: "Away Value" },
    ],
  },
  {
    name: "Player Stat",
    variables: [
      ...team(),
      ...competition,
      ...match(),
      ...player(),
      { name: "Stat Category" },
      { name: "Stat Value" },
      { name: "Subtext" },
    ],
  },
  {
    name: "Player Season Stat",
    variables: [
      ...team(),
      ...competition,
      ...match(),
      ...player(),
      { name: "Stat Category" },
      { name: "Stat Value" },
      { name: "Subtext" },
    ],
  },
  {
    name: "Lineup",
    variables: [
      ...competition,
      ...Manager,
      ...team(),
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...player(7),
      ...player(8),
      ...player(9),
      ...player(10),
      ...player(11),
      ...player(12),
      ...player(13),
      ...player(14),
      ...player(15),
      ...player(16),
      ...player(17),
      ...player(18),
      ...player(19),
      ...player(20),
      ...player(21),
      ...player(22),
      ...player(23),
      { name: "Title" },
    ],
  },

  {
    name: "Lineup Subs",
    variables: [
      ...team(),
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...player(7),
      ...player(8),
      ...player(9),
      ...player(10),
      ...player(11),
      { name: "Title" },
    ],
  },
  {
    name: "Double Lineup Subs",
    variables: [
      ...home_team,
      ...away_team,
      ...Home_Manager,
      ...Away_Manager,
      ...player(1, "Home "),
      ...player(2, "Home "),
      ...player(3, "Home "),
      ...player(4, "Home "),
      ...player(5, "Home "),
      ...player(6, "Home "),
      ...player(7, "Home "),
      ...player(8, "Home "),
      ...player(9, "Home "),
      ...player(10, "Home "),
      ...player(11, "Home "),
      ...player(12, "Home "),
      ...player(13, "Home "),
      ...player(14, "Home "),
      ...player(15, "Home "),
      ...player(16, "Home "),
      ...player(17, "Home "),
      ...player(18, "Home "),
      ...player(19, "Home "),
      ...player(20, "Home "),
      ...player(21, "Home "),
      ...player(22, "Home "),
      ...player(23, "Home "),
      ...player(1, "Away "),
      ...player(2, "Away "),
      ...player(3, "Away "),
      ...player(4, "Away "),
      ...player(5, "Away "),
      ...player(6, "Away "),
      ...player(7, "Away "),
      ...player(8, "Away "),
      ...player(9, "Away "),
      ...player(10, "Away "),
      ...player(11, "Away "),
      ...player(12, "Away "),
      ...player(13, "Away "),
      ...player(14, "Away "),
      ...player(15, "Away "),
      ...player(16, "Away "),
      ...player(17, "Away "),
      ...player(18, "Away "),
      ...player(19, "Away "),
      ...player(20, "Away "),
      ...player(21, "Away "),
      ...player(22, "Away "),
      ...player(23, "Away "),
      { name: "Title" },
    ],
  },
  {
    name: "Team Ticker",
    variables: [
      ...team(),
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...player(7),
      ...player(8),
      { name: "Title" },
    ],
  },
  {
    name: "Clock",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match(),
      { name: "Ref Decision" },
      { name: "Ref Decision Team" },
      { name: "Clock Type" },
      { name: "Sponsor" },
    ],
  },
  {
    name: "Shot Clock",
    variables: [],
  },
  {
    name: "Home Sin Bin",
    variables: [
      { name: "Clock" },
      { name: "Team Type" },
      { name: "Game Time Minutes" },
      { name: "Game Time Seconds" },
    ],
  },
  {
    name: "Away Sin Bin",
    variables: [
      { name: "Clock" },
      { name: "Team Type" },
      { name: "Game Time Minutes" },
      { name: "Game Time Seconds" },
    ],
  },
  {
    name: "Advantage",
    variables: [],
  },
  {
    name: "Manual Shot Clock",
    variables: [{ name: "Clock" }, { name: "Type" }],
  },
  {
    name: "Ref Decision",
    variables: [...team(), { name: "Decision" }],
  },
  {
    name: "Try",
    variables: [...team()],
  },
  {
    name: "Penalty Try",
    variables: [...team()],
  },
  {
    name: "Penalty Goal",
    variables: [...team()],
  },
  {
    name: "Conversion",
    variables: [...team()],
  },
  {
    name: "Drop Goal",
    variables: [...team()],
  },
  {
    name: "Name",
    variables: [
      ...player(),
      ...team(),
      { name: "Subtitle" },
      { name: "Goal Time" },
    ],
  },
  {
    name: "Player of the Match",
    variables: [...player(), ...team(), { name: "Subtitle" }],
  },
  {
    name: "Yellow Card",
    variables: [...player(), ...team()],
  },
  {
    name: "Double Yellow Card",
    variables: [...player(), ...team()],
  },
  {
    name: "Red Card",
    variables: [...player(), ...team()],
  },
  {
    name: "U3 Yellow Card",
    variables: [...player(), ...team()],
  },
  {
    name: "U3 Double Yellow Card",
    variables: [...player(), ...team()],
  },
  {
    name: "U3 Red Card",
    variables: [...player(), ...team()],
  },
  {
    name: "Player Scored",
    variables: [
      ...player(),
      ...team(),
      { name: "Goal Time" },
      { name: "Subtitle" },
    ],
  },
  {
    name: "Substitution",
    variables: [...player(1), ...player(2), ...team()],
  },
  {
    name: "Substitution U3",
    variables: [...player(1), ...player(2), ...team()],
  },
  {
    name: "Two Substitution",
    variables: [
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...team(),
    ],
  },
  {
    name: "Two Substitution U3",
    variables: [
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...team(),
    ],
  },
  {
    name: "Three Substitution",
    variables: [
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...team(),
    ],
  },
  {
    name: "Three Substitution U3",
    variables: [
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...team(),
    ],
  },
  {
    name: "Penalties",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match(),
      ...new Array(15).fill(1).map((_, index) => penalty("Home", index + 1)),
      ...new Array(15).fill(1).map((_, index) => penalty("Away", index + 1)),
    ],
  },
  {
    name: "Formation",
    variables: [
      ...team(),
      ...Manager,
      ...competition,
      ...match(),
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...player(7),
      ...player(8),
      ...player(9),
      ...player(10),
      ...player(11),
      ...player(12),
      ...player(13),
      ...player(14),
      ...player(15),
      ...player(16),
      ...player(17),
      ...player(18),
      ...player(19),
      ...player(20),
      ...player(21),
      ...player(22),
      ...player(23),
      ...player(24),
      ...player(25),
    ],
  },
  {
    name: "Formation Union",
    variables: [
      ...team(),
      ...Manager,
      ...competition,
      ...match(),
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...player(7),
      ...player(8),
      ...player(9),
      ...player(10),
      ...player(11),
      ...player(12),
      ...player(13),
      ...player(14),
      ...player(15),
      ...player(16),
      ...player(17),
      ...player(18),
      ...player(19),
      ...player(20),
      ...player(21),
      ...player(22),
      ...player(23),
      ...player(24),
      ...player(25),
    ],
  },
  {
    name: "Formation Heads",
    variables: [
      ...team(),
      ...Manager,
      ...competition,
      ...match(),
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...player(7),
      ...player(8),
      ...player(9),
      ...player(10),
      ...player(11),
      ...player(12),
      ...player(13),
      ...player(14),
      ...player(15),
      ...player(16),
      ...player(17),
      ...player(18),
      ...player(19),
      ...player(20),
      ...player(21),
      ...player(22),
      ...player(23),
    ],
  },
  {
    name: "Formation Heads Union",
    variables: [
      ...team(),
      ...Manager,
      ...competition,
      ...match(),
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...player(7),
      ...player(8),
      ...player(9),
      ...player(10),
      ...player(11),
      ...player(12),
      ...player(13),
      ...player(14),
      ...player(15),
      ...player(16),
      ...player(17),
      ...player(18),
      ...player(19),
      ...player(20),
      ...player(21),
      ...player(22),
      ...player(23),
    ],
  },
  {
    name: "Standings",
    variables: [
      { name: "Title" },
      ...team(1),
      ...standings(1),
      ...team(2),
      ...standings(2),
      ...team(3),
      ...standings(3),
      ...team(4),
      ...standings(4),
      ...team(5),
      ...standings(5),
      ...team(6),
      ...standings(6),
      ...team(7),
      ...standings(7),
      ...team(8),
      ...standings(8),
      ...team(9),
      ...standings(9),
      ...team(10),
      ...standings(10),
      ...team(11),
      ...standings(11),
      ...team(12),
      ...standings(12),
      ...team(13),
      ...standings(13),
      ...team(14),
      ...standings(14),
      ...team(15),
      ...standings(15),
      ...team(16),
      ...standings(16),
      ...team(17),
      ...standings(17),
      ...team(18),
      ...standings(18),
      ...team(19),
      ...standings(19),
      ...team(20),
      ...standings(20),
    ],
  },
  {
    name: "Standings Mini",
    variables: [
      { name: "Title" },
      ...team(1),
      ...standings(1),
      ...team(2),
      ...standings(2),
      ...team(3),
      ...standings(3),
      ...team(4),
      ...standings(4),
      ...team(5),
      ...standings(5),
      ...team(6),
      ...standings(6),
      ...team(7),
      ...standings(7),
      ...team(8),
      ...standings(8),
      ...team(9),
      ...standings(9),
      ...team(10),
      ...standings(10),
      ...team(11),
      ...standings(11),
      ...team(12),
      ...standings(12),
      ...team(13),
      ...standings(13),
      ...team(14),
      ...standings(14),
      ...team(15),
      ...standings(15),
      ...team(16),
      ...standings(16),
      ...team(17),
      ...standings(17),
      ...team(18),
      ...standings(18),
      ...team(19),
      ...standings(19),
      ...team(20),
      ...standings(20),
    ],
  },
  {
    name: "Formation Double Subs",
    variables: [
      ...home_team,
      ...away_team,
      ...Home_Manager,
      ...Away_Manager,
      ...player(1, "Home "),
      ...player(2, "Home "),
      ...player(3, "Home "),
      ...player(4, "Home "),
      ...player(5, "Home "),
      ...player(6, "Home "),
      ...player(7, "Home "),
      ...player(8, "Home "),
      ...player(9, "Home "),
      ...player(10, "Home "),
      ...player(11, "Home "),
      ...player(12, "Home "),
      ...player(13, "Home "),
      ...player(14, "Home "),
      ...player(15, "Home "),
      ...player(16, "Home "),
      ...player(17, "Home "),
      ...player(18, "Home "),
      ...player(19, "Home "),
      ...player(20, "Home "),
      ...player(21, "Home "),
      ...player(22, "Home "),
      ...player(23, "Home "),
      ...player(1, "Away "),
      ...player(2, "Away "),
      ...player(3, "Away "),
      ...player(4, "Away "),
      ...player(5, "Away "),
      ...player(6, "Away "),
      ...player(7, "Away "),
      ...player(8, "Away "),
      ...player(9, "Away "),
      ...player(10, "Away "),
      ...player(11, "Away "),
      ...player(12, "Away "),
      ...player(13, "Away "),
      ...player(14, "Away "),
      ...player(15, "Away "),
      ...player(16, "Away "),
      ...player(17, "Away "),
      ...player(18, "Away "),
      ...player(19, "Away "),
      ...player(20, "Away "),
      ...player(21, "Away "),
      ...player(22, "Away "),
      ...player(23, "Away "),
      { name: "Title" },
    ],
  },
  {
    name: "Formation Forwards",
    variables: [
      ...team(),
      ...Manager,
      ...competition,
      ...match(),
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...player(7),
      ...player(8),
      ...player(9),
      ...player(10),
      ...player(11),
      ...player(12),
      ...player(13),
      ...player(14),
      ...player(15),
      ...player(16),
      ...player(17),
      ...player(18),
      ...player(19),
      ...player(20),
      ...player(21),
      ...player(22),
      ...player(23),
    ],
  },
  {
    name: "Formation Backs",
    variables: [
      ...team(),
      ...Manager,
      ...competition,
      ...match(),
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...player(7),
      ...player(8),
      ...player(9),
      ...player(10),
      ...player(11),
      ...player(12),
      ...player(13),
      ...player(14),
      ...player(15),
      ...player(16),
      ...player(17),
      ...player(18),
      ...player(19),
      ...player(20),
      ...player(21),
      ...player(22),
      ...player(23),
    ],
  },
  {
    name: "Officials",
    variables: [
      ...competition,
      ...match(),
      ...official("Referee"),
      ...official("Assistant 1"),
      ...official("Assistant 2"),
      ...official("TMO"),
    ],
  },
  {
    name: "Win Prediction",
    variables: [
      ...home_team,
      ...away_team,
      ...Manager,
      ...competition,
      ...match(),
      { name: "Home Percent" },
      { name: "Away Percent" },
      { name: "Draw Percent" },
    ],
  },
  {
    name: "Fixtures",
    variables: [
      { name: "Page" },
      ...competition,
      { name: "Round" },
      ...match(1),
      ...match(2),
      ...match(3),
      ...match(4),
      ...match(5),
      ...match(6),
      ...match(7),
      ...match(8),
      ...match(9),
      ...match(10),
      ...match(11),
      ...match(12),
      ...match(13),
      ...match(14),
      ...match(15),
      ...match(16),
      ...match(17),
      ...match(18),
      ...match(19),
      ...match(20),
      ...match(21),
      ...match(22),
      ...match(23),
      ...match(24),
      ...match(25),
      ...match(26),
      ...match(27),
      ...match(28),
      ...match(29),
      ...match(30),
      ...match(31),
      ...match(32),
      ...match(33),
      ...match(34),
      ...match(35),
      ...match(36),
    ],
  },
  {
    name: "Fixtures Mini",
    variables: [
      { name: "Page" },
      ...competition,
      { name: "Round" },
      ...match(1),
      ...match(2),
      ...match(3),
      ...match(4),
      ...match(5),
      ...match(6),
      ...match(7),
      ...match(8),
      ...match(9),
      ...match(10),
      ...match(11),
      ...match(12),
      ...match(13),
      ...match(14),
      ...match(15),
      ...match(16),
      ...match(17),
      ...match(18),
      ...match(19),
      ...match(20),
      ...match(21),
      ...match(22),
      ...match(23),
      ...match(24),
      ...match(25),
      ...match(26),
      ...match(27),
      ...match(28),
      ...match(29),
      ...match(30),
      ...match(31),
      ...match(32),
      ...match(33),
      ...match(34),
      ...match(35),
      ...match(36),
    ],
  },
];
export default function RugbyData({ scene, app }) {
  const [update, setUpdate] = useState();
  return (
    <Main>
      <Group>
        <Title>Graphic Template</Title>
        {new Array(scene.rugby?.templates?.length || 0)
          .fill(1)
          .map((_, index) => {
            let template = scene.rugby.templates?.[index] || graphics[0];

            return (
              <>
                <Button
                  style={{ fontSize: "15px" }}
                  onClick={() => {
                    scene.rugby.templates = scene.rugby.templates.filter(
                      (t) => {
                        return t.name !== template.name;
                      }
                    );
                    setUpdate(Date.now());
                  }}
                >
                  Remove Template
                </Button>
                <TemplateEditor
                  template={template}
                  scene={scene}
                  app={app}
                  template_index={index}
                  setUpdate={setUpdate}
                  update={update}
                />
              </>
            );
          })}
      </Group>
      <Group
        onClick={() => {
          if (!scene.rugby?.templates?.length) {
            scene.rugby.templates = [];
          }
          scene.rugby.templates.push({ name: graphics[0].name });
          setUpdate(Date.now());
        }}
      >
        <Title>+ Add Template</Title>
      </Group>
    </Main>
  );
}

function TemplateEditor({
  template,
  scene,
  app,
  template_index,
  setUpdate,
  update,
}) {
  const [selectedTemplate, setSelectedTemplate] = useState(
    graphics.find((g) => g.name === template?.name)
  );
  const [nodes, setNodes] = useState([]);
  useEffect(() => {
    setNodes(
      getControls({ children: scene.scene.children, control_array: [] })
    );

    setSelectedTemplate(graphics.find((g) => g.name === template.name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene, template]);

  function getControls({ children, control_array }) {
    let newChildren = children.filter(
      (c) => c.control && c.control.name !== ""
    );
    control_array = [...control_array, ...newChildren];
    let childs = children.filter((c) => c.children && c.children.length > 0);
    for (let i = 0; i < childs.length; i++) {
      control_array = getControls({
        children: childs[i].children,
        control_array,
      });
    }

    return control_array;
  }

  return (
    <>
      <Select
        onChange={(event) => {
          if (!scene.rugby) {
            scene.rugby = {};
          }

          template = event.currentTarget.value;
          scene.rugby.templates[template_index] = {
            ...scene.rugby.templates[template_index],
            name: template,
          };
          app.setState(app.state);

          setSelectedTemplate(
            graphics.find((graphic) => graphic.name === template)
          );
          setUpdate(Date.now());
        }}
        value={selectedTemplate.name}
      >
        {graphics.map((graphic, index) => {
          return <option value={graphic.name}>{graphic.name}</option>;
        })}
      </Select>
      {selectedTemplate && (
        <VariablesTable>
          <Row>
            <Column>Node</Column>
            <Column>Data variable</Column>
            <Column>Default value</Column>
            <Column></Column>
          </Row>
          {nodes?.map((node) => {
            return (
              <Container>
                <Row>
                  <Column>{node?.control?.name}</Column>
                </Row>
                <Row>
                  <Column>
                    <Select
                      onChange={(ev) => {
                        let item = scene.getItemByUUID(
                          node.uuid,
                          scene.scene.children
                        );

                        if (!item.control?.data) {
                          item.control.data = { variables: [] };
                        }
                        if (!item.control.data.rugby) {
                          item.control.data.rugby = { variables: [] };
                        }

                        item.control.data.rugby.variables =
                          item.control.data?.rugby?.variables.filter(
                            (v) => v.template !== selectedTemplate.name
                          );
                        item.control.data.rugby.variables.push({
                          template: selectedTemplate.name,
                          field: ev.currentTarget.value,
                        });
                        setUpdate(Date.now());
                      }}
                      value={
                        node?.control?.data?.rugby?.variables?.find(
                          (v) => v.template === selectedTemplate.name
                        )?.field
                      }
                    >
                      <option value="">---</option>
                      {selectedTemplate?.variables?.map((variable, index) => {
                        return (
                          <option value={variable.name}>{variable.name}</option>
                        );
                      })}
                    </Select>
                  </Column>
                  <Column>
                    <Input
                      value={
                        node?.control?.data?.rugby?.variables?.find(
                          (v) => v.template === selectedTemplate.name
                        )?.default
                      }
                      onChange={(e) => {
                        let item = scene.getItemByUUID(
                          node.uuid,
                          scene.scene.children
                        );

                        if (!item.control?.data) {
                          item.control.data = { variables: [] };
                        }
                        if (!item.control.data.rugby) {
                          item.control.data.rugby = { variables: [] };
                        }
                        let index = item.control.data.rugby.variables.findIndex(
                          (v) => v.template === selectedTemplate.name
                        );
                        if (index === -1) {
                          item.control.data.rugby.variables.push({
                            template: selectedTemplate.name,
                            default: e.currentTarget.value,
                          });
                        } else {
                          item.control.data.rugby.variables[index].default =
                            e.currentTarget.value;
                        }

                        setUpdate(Date.now());
                      }}
                    />
                  </Column>
                  <Column>
                    <Input
                      type="checkbox"
                      checked={
                        node?.control?.data?.rugby?.variables?.find(
                          (v) => v.template === selectedTemplate.name
                        )?.hide
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        let item = scene.getItemByUUID(
                          node.uuid,
                          scene.scene.children
                        );

                        if (!item.control?.data) {
                          item.control.data = { variables: [] };
                        }
                        if (!item.control.data.rugby) {
                          item.control.data.rugby = { variables: [] };
                        }
                        let index = item.control.data.rugby.variables.findIndex(
                          (v) => v.template === selectedTemplate.name
                        );
                        if (index === -1) {
                          item.control.data.rugby.variables.push({
                            template: selectedTemplate.name,
                            hide: e.currentTarget.checked,
                          });
                        } else {
                          item.control.data.rugby.variables[index].hide =
                            e.currentTarget.checked;
                        }

                        setUpdate(Date.now());
                      }}
                    />
                  </Column>
                </Row>
              </Container>
            );
          })}
        </VariablesTable>
      )}
    </>
  );
}
