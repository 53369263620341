import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../../Theme/Hyper";
const Select = styled.select`
  background-color: #181d25;
  color: #fff;
  padding: 4px;
  border: none;
  margin: 4px;
  border-radius: 4px;
  max-width: 100px;
`;
const Main = styled.div`
  padding: 0.5em;
  width: 440px;
  height: calc(100vh - 85px);
  background-color: #181d25;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  background-color: #1f252f;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -20px rgba(0, 0, 0, 0.2),
    0px 5px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
`;

const Title = styled.div`
  font-size: 1.1em;
  background-color: #282f3c;
  padding: 0.3em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #161a21;
`;

const Row = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
`;

const VariablesTable = styled.div`
  margin-top: 10px;
`;
const Column = styled.div`
  display: flex;
  width: 25%;
`;
const Input = styled.input`
  border: 1px solid #232531;
  padding: 4px;
  font-size: 0.9em;
  background-color: #12161d;
  border-radius: 3px;
  color: #fff;
  max-width: 100px;
`;

const athlete = (index) => {
  if (index) {
    return [
      { name: index + " Athlete Headshot" },
      { name: index + " Athlete First Name" },
      { name: index + " Athlete Last Name" },
      { name: index + " Athlete Full Name" },
      { name: index + " Athlete Country" },
      { name: index + " Athlete Country Flag" },
      { name: index + " Athlete Bib" },
      { name: index + " Athlete Age" },
      { name: index + " Athlete Height" },
      { name: index + " Athlete Position" },
      { name: index + " Top" },
      { name: index + " Zone" },
      { name: index + " Run" },
      { name: index + " Top Attempts" },
      { name: index + " Zone Attempts" },
      { name: index + " Overall Points" },
      { name: index + " Active Top Attempts" },
      { name: index + " Active Zone Attempts" },
      { name: index + " Athlete PB" },
      { name: index + " Athlete Season PB" },
      { name: index + " Athlete Progression Rate" },
      { name: index + " Athlete Head 2 Head" },
      ...route(1, index),
      ...route(2, index),
      ...route(3, index),
      ...route(4, index),
    ];
  } else {
    return [
      { name: "Athlete Headshot" },
      { name: "Athlete First Name" },
      { name: "Athlete Last Name" },
      { name: "Athlete Full Name" },
      { name: "Athlete Country" },
      { name: "Athlete Country Flag" },
      { name: "Athlete Bib" },
      { name: "Athlete Age" },
      { name: "Athlete Height" },
      { name: "Athlete Position" },
      { name: "Top" },
      { name: "Zone" },
      { name: "Top Attempts" },
      { name: "Zone Attempts" },
      { name: "Overall Points" },
      { name: "Run" },
      { name: "Active Top Attempts" },
      { name: "Active Zone Attempts" },
      ...route(1),
      ...route(2),
      ...route(3),
      ...route(4),
    ];
  }
};

const athleteSpeed = (index) => {
  if (index) {
    return [
      { name: index + " Athlete First Name" },
      { name: index + " Athlete Last Name" },
      { name: index + " Athlete Full Name" },
      { name: index + " Athlete Country" },
      { name: index + " Athlete Country Flag" },
      { name: index + " Athlete Bib" },
      { name: index + " Athlete Age" },
      { name: index + " Athlete Height" },
      { name: index + " Athlete Position" },
      { name: index + " Athlete Time 1" },
      { name: index + " Athlete Time 2" },
    ];
  } else {
    return [
      { name: "Athlete First Name" },
      { name: "Athlete Last Name" },
      { name: "Athlete Full Name" },
      { name: "Athlete Country" },
      { name: "Athlete Country Flag" },
      { name: "Athlete Bib" },
      { name: "Athlete Age" },
      { name: "Athlete Height" },
      { name: "Athlete Position" },
      { name: "time" },
    ];
  }
};

const heat = (index) => {
  if (index) {
    return [
      { name: "Heat " + index + " Athlete 1 First Name" },
      { name: "Heat " + index + " Athlete 1 Last Name" },
      { name: "Heat " + index + " Athlete 1 Full Name" },
      { name: "Heat " + index + " Athlete 1 Country" },
      { name: "Heat " + index + " Athlete 1 Country Flag" },
      { name: "Heat " + index + " Athlete 1  time" },
      { name: "Heat " + index + " Athlete 2 First Name" },
      { name: "Heat " + index + " Athlete 2 Last Name" },
      { name: "Heat " + index + " Athlete 2 Full Name" },
      { name: "Heat " + index + " Athlete 2 Country" },
      { name: "Heat " + index + " Athlete 2 Country Flag" },
      { name: "Heat " + index + " Athlete 2 time" },
    ];
  }
};

const podium = (index) => {
  if (index) {
    return [
      { name: "Podium " + index + " Athlete First Name" },
      { name: "Podium " + index + " Athlete Last Name" },
      { name: "Podium " + index + " Athlete Full Name" },
      { name: "Podium " + index + " Athlete Country" },
      { name: "Podium " + index + " Athlete Country Flag" },
      { name: "Podium " + index + " Athlete time" },
    ];
  }
};

const route = (index, athlete) => {
  if (athlete) {
    return [
      { name: athlete + " Athlete Route " + index + " Image" },
      { name: athlete + " Athlete Route " + index + " Top" },
      { name: athlete + " Athlete Route " + index + " Top Tries" },
      { name: athlete + " Athlete Route " + index + " Zone" },
      { name: athlete + " Athlete Route " + index + " Zone Tries" },
    ];
  } else if (index) {
    return [
      { name: "Route " + index + " Image" },
      { name: "Route " + index + " Top" },
      { name: "Route " + index + " Top Tries" },
      { name: "Route " + index + " Zone" },
      { name: "Route " + index + " Zone Tries" },
    ];
  } else {
    return [
      { name: "Route Image" },
      { name: "Route Top" },
      { name: "Route Top Tries" },
      { name: "Route Zone" },
      { name: "Route Zone Tries" },
    ];
  }
};

const graphics = [
  {
    name: "LTAthlete",
    variables: [
      { name: "Competition Title" },
      { name: "Category Title" },
      { name: "Category subtitle" },
      ...athlete(),
    ],
  },
  {
    name: "LTMatchUp",
    variables: [
      { name: "Competition Title" },
      { name: "Category Title" },
      { name: "Category subtitle" },
      ...athlete(1),
      { name: "1 Athlete Time" },
      { name: "1 Athlete Reaction Time" },
      { name: "1 Athlete Winner" },
      { name: "1 Athlete False Start" },
      ...athlete(2),
      { name: "2 Athlete Time" },
      { name: "2 Athlete Reaction Time" },
      { name: "2 Athlete Winner" },
      { name: "2 Athlete False Start" },
    ],
  },
  {
    name: "FFMatchUp",
    variables: [
      { name: "Competition Title" },
      { name: "Category Title" },
      { name: "Category subtitle" },
      ...athlete(1),
      ...athlete(2),
      ...athlete(3),
      ...athlete(4),
      ...athlete(5),
      ...athlete(6),
      ...athlete(7),
      ...athlete(8),
      ...athlete(9),
      ...athlete(10),
    ],
  },
  {
    name: "FFMatchUp4",
    variables: [
      { name: "Competition Title" },
      { name: "Category Title" },
      { name: "Category subtitle" },
      ...athlete(1),
      ...athlete(2),
      ...athlete(3),
      ...athlete(4),
      ...athlete(5),
      ...athlete(6),
      ...athlete(7),
      ...athlete(8),
      ...athlete(9),
      ...athlete(10),
    ],
  },
  {
    name: "BoulderBugs",
    variables: [
      { name: "Boulder 1" },
      { name: "Boulder 2" },
      { name: "Boulder 3" },
      { name: "Boulder 4" },
    ],
  },
  {
    name: "BoulderBug",
    variables: [{ name: "Boulder" }],
  },
  {
    name: "FFHeadtohead",
    variables: [
      { name: "Competition Title" },
      { name: "Category Title" },
      { name: "Category subtitle" },
      ...athlete(1),
      ...athlete(2),
    ],
  },
  {
    name: "FFTable",
    variables: [
      { name: "Competition Title" },
      { name: "Category Title" },
      { name: "Category subtitle" },
      { name: "Show Split" },
      ...athlete(1),
      ...athlete(2),
      ...athlete(3),
      ...athlete(4),
      ...athlete(5),
      ...athlete(6),
      ...athlete(7),
      ...athlete(8),
      ...athlete(9),
      ...athlete(10),
    ],
  },
  {
    name: "Speed FFTable",
    variables: [
      { name: "Competition Title" },
      { name: "Category Title" },
      { name: "Category subtitle" },
      ...athleteSpeed(1),
      ...athleteSpeed(2),
      ...athleteSpeed(3),
      ...athleteSpeed(4),
      ...athleteSpeed(5),
      ...athleteSpeed(6),
      ...athleteSpeed(7),
      ...athleteSpeed(8),
      ...athleteSpeed(9),
      ...athleteSpeed(10),
    ],
  },
  {
    name: "Speed FFBracket",
    variables: [
      { name: "Competition Title" },
      { name: "Category Title" },
      { name: "Category subtitle" },
      ...heat(1),
      ...heat(2),
      ...heat(3),
      ...heat(4),
      ...heat(5),
      ...heat(6),
      ...heat(7),
      ...heat(8),
      ...heat(9),
      ...heat(10),
      ...heat(11),
      ...heat(12),
      ...heat(13),
      ...heat(14),
      ...heat(15),
      ...heat(16),
      ...podium(1),
      ...podium(2),
      ...podium(3),
    ],
  },
  {
    name: "Athlete Intro",
    variables: [{ name: "Competition Title" }, ...athlete()],
  },
  {
    name: "Athlete Active",
    variables: [
      { name: "Competition Title" },
      ...athlete(),
      { name: "Active Route Name" },
    ],
  },
  {
    name: "Athlete Profile",
    variables: [
      { name: "Competition Title" },
      ...athlete(),
      { name: "Gold" },
      { name: "Silver" },
      { name: "Bronze" },
      { name: "Participations" },
      { name: "Tops" },
    ],
  },
  {
    name: "Winner",
    variables: [{ name: "Competition Title" }, ...athlete()],
  },
  {
    name: "Clock",

    variables: [{ name: "Clock" }, { name: "Clock Type" }],
  },
  {
    name: "Run",

    variables: [{ name: "Run" }],
  },
  {
    name: "FFStartList",
    variables: [
      { name: "Competition Title" },
      { name: "Category Title" },
      { name: "Category subtitle" },
      ...athlete(1),
      ...athlete(2),
      ...athlete(3),
      ...athlete(4),
      ...athlete(5),
      ...athlete(6),
      ...athlete(7),
      ...athlete(8),
      ...athlete(9),
      ...athlete(10),
    ],
  },
];
export default function ClimbingData({ scene, app }) {
  const [update, setUpdate] = useState();
  return (
    <Main>
      <Group>
        <Title>Graphic Template</Title>
        {new Array(scene.climbing?.templates?.length || 0)
          .fill(1)
          .map((_, index) => {
            let template = scene.climbing.templates?.[index] || graphics[0];

            return (
              <>
                <Button
                  style={{ fontSize: "15px" }}
                  onClick={() => {
                    scene.climbing.templates = scene.climbing.templates.filter(
                      (t) => {
                        return t?.name !== template?.name;
                      }
                    );
                    setUpdate(Date.now());
                  }}
                >
                  Remove Template
                </Button>
                <TemplateEditor
                  template={template}
                  scene={scene}
                  app={app}
                  template_index={index}
                  setUpdate={setUpdate}
                  update={update}
                />
              </>
            );
          })}
      </Group>
      <Group
        onClick={() => {
          if (!scene.climbing?.templates?.length) {
            scene.climbing.templates = [];
          }
          scene.climbing.templates.push({ name: graphics?.[0]?.name });
          setUpdate(Date.now());
        }}
      >
        <Title>+ Add Template</Title>
      </Group>
    </Main>
  );
}

function TemplateEditor({
  template,
  scene,
  app,
  template_index,
  setUpdate,
  update,
}) {
  const [selectedTemplate, setSelectedTemplate] = useState(
    graphics.find((g) => g.name === template?.name)
  );
  const [nodes, setNodes] = useState([]);
  useEffect(() => {
    setNodes(
      getControls({ children: scene.scene.children, control_array: [] })
    );

    setSelectedTemplate(graphics.find((g) => g.name === template.name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene, template]);

  function getControls({ children, control_array }) {
    let newChildren = children.filter(
      (c) => c.control && c.control.name !== ""
    );
    control_array = [...control_array, ...newChildren];
    let childs = children.filter((c) => c.children && c.children.length > 0);
    for (let i = 0; i < childs.length; i++) {
      control_array = getControls({
        children: childs[i].children,
        control_array,
      });
    }

    return control_array;
  }

  return (
    <>
      <Select
        style={{ maxWidth: "200px" }}
        onChange={(event) => {
          if (!scene.climbing) {
            scene.climbing = {};
          }

          template = event.currentTarget.value;
          scene.climbing.templates[template_index] = {
            ...scene.climbing.templates[template_index],
            name: template,
          };
          app.setState(app.state);

          setSelectedTemplate(
            graphics.find((graphic) => graphic.name === template)
          );
          setUpdate(Date.now());
        }}
        value={selectedTemplate?.name}
      >
        {graphics.map((graphic, index) => {
          return <option value={graphic?.name}>{graphic?.name}</option>;
        })}
      </Select>
      {selectedTemplate && (
        <VariablesTable>
          <Row>
            <Column>Node</Column>
            <Column>Data variable</Column>
            <Column>Default value</Column>
            <Column></Column>
          </Row>
          {nodes?.map((node) => {
            return (
              <Row>
                <Column>{node?.control?.name}</Column>
                <Column>
                  <Select
                    onChange={(ev) => {
                      let item = scene.getItemByUUID(
                        node.uuid,
                        scene.scene.children
                      );

                      if (!item.control?.data) {
                        item.control.data = { variables: [] };
                      }
                      if (!item.control.data.climbing) {
                        item.control.data.climbing = { variables: [] };
                      }

                      item.control.data.climbing.variables =
                        item.control.data?.climbing?.variables.filter(
                          (v) => v.template !== selectedTemplate?.name
                        );
                      item.control.data.climbing.variables.push({
                        template: selectedTemplate?.name,
                        field: ev.currentTarget.value,
                      });
                      setUpdate(Date.now());
                    }}
                    value={
                      node?.control?.data?.climbing?.variables?.find(
                        (v) => v.template === selectedTemplate?.name
                      )?.field
                    }
                  >
                    <option value="">---</option>
                    {selectedTemplate?.variables?.map((variable, index) => {
                      return (
                        <option value={variable?.name}>{variable?.name}</option>
                      );
                    })}
                  </Select>
                </Column>
                <Column>
                  <Input
                    value={
                      node?.control?.data?.climbing?.variables?.find(
                        (v) => v.template === selectedTemplate?.name
                      )?.default
                    }
                    onChange={(e) => {
                      let item = scene.getItemByUUID(
                        node.uuid,
                        scene.scene.children
                      );

                      if (!item.control?.data) {
                        item.control.data = { variables: [] };
                      }
                      if (!item.control.data.climbing) {
                        item.control.data.climbing = { variables: [] };
                      }
                      let index =
                        item.control.data.climbing.variables.findIndex(
                          (v) => v.template === selectedTemplate?.name
                        );
                      if (index === -1) {
                        item.control.data.climbing.variables.push({
                          template: selectedTemplate?.name,
                          default: e.currentTarget.value,
                        });
                      } else {
                        item.control.data.climbing.variables[index].default =
                          e.currentTarget.value;
                      }

                      setUpdate(Date.now());
                    }}
                  />
                </Column>
                <Column>
                  <Input
                    type="checkbox"
                    checked={
                      node?.control?.data?.climbing?.variables?.find(
                        (v) => v.template === selectedTemplate?.name
                      )?.hide === true
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      let item = scene.getItemByUUID(
                        node.uuid,
                        scene.scene.children
                      );

                      if (!item.control?.data) {
                        item.control.data = { variables: [] };
                      }
                      if (!item.control.data.climbing) {
                        item.control.data.climbing = { variables: [] };
                      }
                      let index =
                        item.control.data.climbing.variables.findIndex(
                          (v) => v.template === selectedTemplate?.name
                        );
                      if (index === -1) {
                        item.control.data.climbing.variables.push({
                          template: selectedTemplate?.name,
                          hide: e.currentTarget.checked,
                        });
                      } else {
                        item.control.data.climbing.variables[index].hide =
                          e.currentTarget.checked;
                      }

                      setUpdate(Date.now());
                    }}
                  />
                </Column>
                <Column>
                  <Input
                    type="checkbox"
                    checked={
                      node?.control?.data?.climbing?.variables?.find(
                        (v) => v.template === selectedTemplate?.name
                      )?.hide === "undefined"
                    }
                    onChange={(e) => {
                      let item = scene.getItemByUUID(
                        node.uuid,
                        scene.scene.children
                      );

                      if (!item.control?.data) {
                        item.control.data = { variables: [] };
                      }
                      if (!item.control.data.climbing) {
                        item.control.data.climbing = { variables: [] };
                      }
                      let index =
                        item.control.data.climbing.variables.findIndex(
                          (v) => v.template === selectedTemplate?.name
                        );
                      if (index === -1) {
                        item.control.data.climbing.variables.push({
                          template: selectedTemplate?.name,
                          hide: "undefined",
                        });
                      } else {
                        item.control.data.climbing.variables[index].hide =
                          "undefined";
                      }

                      setUpdate(Date.now());
                    }}
                  />
                </Column>
              </Row>
            );
          })}
        </VariablesTable>
      )}
    </>
  );
}
