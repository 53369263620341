import Scene from "./Scene";
import XMLDataSource from "../DataSources/xml";
import gql from "graphql-tag";

const PIXI = window.PIXI;
class Project {
  constructor(
    name,
    player,
    assets,
    apolloClient,
    _id,
    user,
    use_project_folder = false,
    engine_version = "0.0.0"
  ) {
    this.apolloClient = apolloClient.client;
    this.scenes = [];
    this.name = name;
    this.stage = player.stage;
    this.player = player;
    this.assets = assets;
    this.backgroundColor = "#8c48e6";
    this.loader = PIXI.Loader.shared;
    this.use_project_folder = use_project_folder;
    this.loadedAssets = 0;
    this.dataSources = [];
    this._id = _id;
    this.user = user;
    this.engine_version = engine_version;
  }

  onEngineStartUp() {
    for (let i = 0; i < this.scenes.length; i++) {
      let scene = this.scenes[i];
      scene.onEngineStartUp();
    }
  }

  loadDatasources(dataSources) {
    this.dataSources = dataSources.map((ds) => {
      return new XMLDataSource(ds);
    });
  }

  checkFree(user) {
    if (user?.license_type === "FREE") {
      var sprite = PIXI.Sprite.from(
        process.env.PUBLIC_URL + "/Images/HyperLogo.png"
      );
      sprite.x = 100;
      sprite.y = 1000;
      sprite.width = 180;
      sprite.height = 37;
      sprite.type = "IMAGE";
      this.stage.addChild(sprite);
      let style = new PIXI.TextStyle({
        fontFamily: "Arial",
        fontSize: 36,
        fill: "white",
      });
      let message = new PIXI.Text("FREE LICENSE", style);
      this.stage.addChild(message);
    }
  }
  reload() {
    return new Promise((resolve, reject) => {
      this.loadProject(this.user, () => {
        resolve();
      });
    });
  }
  loadProject(user, cb) {
    this.user = user;
    this.loading = true;

    if (this.player.stage) {
      this.player.stage.destroy({
        children: true,
        texture: true,
        baseTexture: true,
      });
      this.player.createStage();
      this.stage = this.player.stage;
    }

    let this_project = this;
    this.loadAssets(() => {
      let path_id = this.use_project_folder ? this._id : this.user._id;
      let prefix = this.use_project_folder ? "project/" : "";

      fetch(`${window.projectServerURL}assets/fonts/${prefix}${path_id}`)
        .then((res) => res.json())
        .catch((err) => {
          return { children: [] };
        })
        .then(async (data) => {
          window.document.fonts.clear();
          let fonts = window.document.fonts.entries();
          let done = false;
          let font_array = [];

          while (!done) {
            const font = fonts.next();
            if (!font.done) {
              font_array.push(font.value[0]);
              // console.log(font.value[0])
            } else {
              done = font.done;
            }
          }

          let promises = data
            .filter((f) => {
              return font_array.findIndex((fo) => fo.family === f.name) === -1;
            })
            .map((f) => {
              let font = new FontFace(
                f.name,
                "url('" +
                  window.projectServerURL +
                  "assets/fonts/" +
                  prefix +
                  path_id +
                  "/" +
                  f.name +
                  "')"
              );
              return font
                .load()
                .then((loadedFont) => {
                  window.document.fonts.add(loadedFont);
                })
                .catch((err) => {
                  console.error(err);
                });
            });

          await Promise.all(promises);

          this.apolloClient
            .query({
              query: gql`
                query GetScenes {
                  scenesInProject(project_id: "${this._id}") {
                    name
                    data
                    _id
                  }
                }
              `,
              fetchPolicy: "network-only",
            })
            .then((data) => {
              this_project.scenes = [];
              data.data.scenesInProject.forEach((s) => {
                let scene = new Scene(
                  s.name,
                  this_project.stage,
                  s._id,
                  this_project.apolloClient,
                  user,
                  this_project._id,
                  this_project,
                  s.data.archived
                );
                scene.load(JSON.parse(JSON.stringify(s.data)));
                this_project.scenes.push(scene);
              });
              this.checkFree(user);
              this_project.player.project = this_project;
              this_project.player.selectedScene = this_project.scenes?.sort(
                (a, b) => {
                  return a?.name.localeCompare(b?.name);
                }
              )?.[0];
              if (this_project.player.selectedScene) {
                this_project.player.selectedScene.activeTimeline =
                  this_project.player.selectedScene.timelines[0];
              }
              cb();

              this.loading = false;
            });
        });
    });
  }

  loadAssets(then) {
    then();
  }

  async addNewScene(name = "New Scene") {
    let scene = new Scene(
      name,
      this.stage,
      "",
      this.apolloClient,
      this.user,
      this._id,
      this
    );
    scene.setup();
    let scene_json = JSON.stringify(scene.save());
    let json = JSON.parse(scene_json);

    let doc = await this.apolloClient.mutate({
      mutation: gql`
        mutation Mutation($name: String!, $data: JSON!, $project_id: String!) {
          addScene(name: $name, data: $data, project_id: $project_id) {
            name
            _id
          }
        }
      `,
      variables: { data: json, name: "New Scene", project_id: this._id },
    });

    scene._id = doc.data.addScene._id;
    this.scenes.push(scene);
    return scene;
  }

  async importScene({ data, name }) {
    let scene = new Scene(
      name,
      this.stage,
      "",
      this.apolloClient,
      this.user,
      this._id,
      this
    );
    let scene_index = this.scenes.findIndex((s) => s.name === name);

    scene.load(JSON.parse(JSON.stringify(data)));
    if (scene_index === -1) {
      let doc = await this.apolloClient.mutate({
        mutation: gql`
          mutation Mutation(
            $name: String!
            $data: JSON!
            $project_id: String!
          ) {
            addScene(name: $name, data: $data, project_id: $project_id) {
              name
              _id
            }
          }
        `,
        variables: { data: data, name: name, project_id: this._id },
      });
      scene._id = doc.data.addScene._id;
      this.scenes.push(scene);
    } else {
      let doc = await this.apolloClient.mutate({
        mutation: gql`
          mutation Mutation($id: String!, $data: JSON!) {
            updateScene(id: $id, data: $data) {
              _id
            }
          }
        `,
        variables: { data: data, id: this.scenes[scene_index]._id },
      });
      scene._id = doc.data.updateScene._id;
      this.scenes[scene_index] = scene;
    }

    return scene;
  }

  async deleteScene(scene) {
    await this.apolloClient.mutate({
      mutation: gql`
        mutation Mutation($id: String!) {
          deleteScene(id: $id)
        }
      `,
      variables: { id: scene._id },
    });

    var index = this.scenes.indexOf(scene);
    this.scenes.splice(index, 1);
    delete this.selectedScene;
  }

  async duplicateScene(scene, aspect) {
    var index = this.scenes.indexOf(scene);
    let newScene = JSON.parse(JSON.stringify(scene.save()));
    newScene.name = scene.name;
    if (!aspect) {
      newScene.name = newScene.name + "_COPY";
    }
    newScene.aspect = aspect;

    let s = new Scene(
      newScene.name,
      this.stage,
      "",
      this.apolloClient,
      this.user,
      this._id,
      this
    );

    s.load(newScene);

    let json = JSON.parse(JSON.stringify(s.save()));
    let doc = await this.apolloClient.mutate({
      mutation: gql`
        mutation Mutation($name: String!, $data: JSON!, $project_id: String!) {
          addScene(name: $name, data: $data, project_id: $project_id) {
            name
            _id
          }
        }
      `,
      variables: { data: json, name: s.name, project_id: this._id },
    });
    s._id = doc.data.addScene._id;
    this.scenes.splice(index + 1, 0, s);
    //this.scenes.splice(index, 1);
    //delete this.selectedScene;
  }

  async save(scene) {
    let obj = {
      name: this.name,
      backgroundColor: this.backgroundColor,
      scenes: [],
      dataSources: this.dataSources.map((ds) => {
        return ds.saveToJson();
      }),
    };

    let resources = Object.keys(PIXI.utils.TextureCache);

    obj.assets = resources
      .filter((item) => {
        return (
          item.indexOf("pixiid") === -1 && item.indexOf("undefined") === -1
        );
      })
      .sort();

    obj.assets = [...new Set(obj.assets)];

    let promises = [];
    for (let i = 0; i < this.scenes.length; i++) {
      obj.scenes.push(this.scenes[i].name);
      if (!scene || scene === "" || scene === this.scenes[i].name) {
        let scene_json = JSON.stringify(this.scenes[i].save());
        let json = JSON.parse(scene_json);

        promises.push(
          this.apolloClient.mutate({
            mutation: gql`
              mutation Mutation($id: String!, $data: JSON!) {
                updateScene(id: $id, data: $data) {
                  name
                }
              }
            `,
            variables: { data: json, id: this.scenes[i]._id },
          })
        );
      }
    }
    // fetch(`${window.projectServerURL}project/${this.name}`, {
    //   method: "PUT",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(obj),
    // });

    console.log(JSON.stringify(obj));
    return Promise.all(promises);
  }
}

export default Project;
