import React, { Component } from "react";
import styles from "./AudioViewer.module.css";
import styled from "styled-components";
import Popup from "../Dialog/Popup";
const PopupDiv = styled.div`
  background-color: ${(props) => props.theme.panelColour};
  border-radius: 4px;
  width: 200px;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  font-size: 0.8em;
  overflow: hidden;
`;
const PopupItem = styled.div`
  padding: 0.5em;
  :hover {
    background-color: #d11e42;
  }
  /* border-bottom: 1px solid grey; */
`;
const PopupGroup = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
`;

export default class AudioViewer extends Component {
  constructor(props) {
    super(props);
    this.userid = localStorage.getItem("user-id");

    this.state = {
      audio: [],
      showDeleteAudioPopup: false,
      audioContextMenuItem: {
        showContext: false,
      },
    };
  }

  componentDidMount() {
    this.getAudio();
  }

  getAudio() {
    let path_id = this.props.project?.use_project_folder
      ? this.props.project?._id
      : this.props.project?.user._id;
    let prefix = this.props.project?.use_project_folder ? "project/" : "";

    fetch(`${window.projectServerURL}assets/audio/${prefix}${path_id}`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          audio: data.map((audio) => audio.name),
        });
      });
  }

  upload(e) {
    const files = Array.from(e.target.files);

    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append("file", file);
    });

    let path_id = this.props.project?.use_project_folder
      ? this.props.project?._id
      : this.props.project?.user._id;
    let prefix = this.props.project?.use_project_folder ? "project/" : "";

    fetch(`${window.projectServerURL}assets/audio/${prefix}${path_id}`, {
      method: "POST",
      body: formData,
    }).then((data) => {
      this.getAudio();
    });
  }

  deleteAudio() {
    let user = localStorage.getItem("user-id");
    let id = this.props.project?.use_project_folder
      ? this.props.project._id
      : user;
    let prefix = this.props.project?.use_project_folder ? "project/" : "";

    fetch(
      `${
        window.projectServerURL
      }assets/audio/${prefix}${id}/${window.encodeURIComponent(
        this.state.audioContextMenuItem.item.replace(/ /g, "_")
      )}`,
      {
        method: "DELETE",
      }
    )
      .then((response) => {
        this.getAudio();
      })
      .finally(() => {
        this.setState({
          ...this.state,
          showDeleteAudioPopup: false,
          audioContextMenuItem: {
            showContext: false,
          },
        });
      });
  }

  handleAudioContextMenu(e, item) {
    this.setState({
      ...this.state,
      audioContextMenuItem: {
        item: item,
        mouseX: e.clientX,
        mouseY: e.clientY,
        showContext: true,
      },
    });
    e.preventDefault();
    this.click = () => this.handleClick();
    window.addEventListener("click", this.click);
  }

  handleClick() {
    this.setState({
      ...this.state,
      audioContextMenuItem: {
        ...this.state.audioContextMenuItem,
        showContext: false,
      },
    });
    window.removeEventListener("click", this.click);
  }

  render() {
    return (
      <div className={styles.ImageViewer}>
        <div
          className={styles.AddContainer}
          onClick={() => document.getElementById("audio-input").click()}
        >
          +
        </div>
        <input
          id="audio-input"
          type="file"
          name="audio"
          accept=".wav,.mp3"
          style={{ display: "none" }}
          onChange={(e) => {
            this.upload(e);
          }}
        />
        <div className={styles.Images}>
          {this.state.audio.map((audio, index) => {
            let path_id = this.props.project?.use_project_folder
              ? this.props.project?._id
              : this.props.project?.user._id;
            let prefix = this.props.project?.use_project_folder
              ? "project/"
              : "";

            return (
              <div
                key={index}
                className={styles.Image}
                onClick={() => {
                  this.props.callback(
                    `${window.projectServerURL}assets/audio/${prefix}${path_id}/${audio}`
                  );
                }}
                onContextMenu={(e) => {
                  this.handleAudioContextMenu(e, audio);
                }}
              >
                <div className={styles.Overlay}>{audio}</div>
              </div>
            );
          })}
        </div>
        {this.state.showDeleteAudioPopup && (
          <Popup
            title={"Delete Audio"}
            message={
              "THIS WILL DELETE THE AUDIO BE CAREFUL IF IT'S USED IN OTHER PAGES!!"
            }
            onSave={() => this.deleteAudio()}
            onCancel={() => {
              this.setState({ ...this.state, showDeleteAudioPopup: false });
            }}
          />
        )}

        {this.state.audioContextMenuItem &&
          this.state.audioContextMenuItem.showContext && (
            <PopupDiv
              style={{
                position: "absolute",
                left: this.state?.audioContextMenuItem?.mouseX + 10,
                top: this.state?.audioContextMenuItem?.mouseY,
                zIndex: 1000,
              }}
            >
              <PopupGroup>
                <PopupItem
                  onClick={() => {
                    this.setState({
                      ...this.state,

                      showDeleteAudioPopup: true,
                    });
                  }}
                >
                  Delete
                </PopupItem>
              </PopupGroup>
            </PopupDiv>
          )}
      </div>
    );
  }
}
