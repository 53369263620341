import React, { Component } from "react";
import propertiesStyle from "./Properties.module.css";
import styles from "./Material.module.css";
import TriangleDown from "../../SVG/TriangleDown";
import TriangleRight from "../../SVG/TriangleRight";
import {
  setProperty as SET_PROPERTY,
  redrawCircle,
  redrawRectangle,
} from "../../Scripts/PropertyUtils";
import Play from "../../SVG/Play";
import styled from "styled-components";
export default class Audio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      audioIndex: 0,
    };
  }

  updateProperty(property, value) {
    value = value;

    SET_PROPERTY(this.props.item, property, value);
  }

  drag(e, property, value) {
    e.dataTransfer.setData("property", property);
    e.dataTransfer.setData("value", value);
    console.log(e);
  }

  handleMouseDown(e, property, startValue) {
    this.property = property;
    this.mouseDown = true;
    var rect = document.querySelector("body").getBoundingClientRect();
    var x = e.clientX - rect.left; //x position within the element.
    this.startX = x;

    this.startValue = startValue;
    document.addEventListener("mousemove", this.handleMouseMove.bind(this));
    document.addEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  handleMouseMove(e) {
    if (this.mouseDown) {
      var rect = document.querySelector("body").getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      x = x - this.startX;

      this.updateProperty(this.property, parseFloat(this.startValue) + x);
    }
  }

  handleMouseUp(e) {
    this.mouseDown = false;
    document.removeEventListener("mousemove", this.handleMouseMove.bind(this));
    document.removeEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  audioCallback(audio) {
    this.props.item.audio_player.src = audio;
    this.props.item.audio_player.load();
    this.forceUpdate();
  }

  render() {
    return (
      <div className={propertiesStyle.Group}>
        <div
          className={propertiesStyle.GroupTitle}
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <div className={propertiesStyle.Open}>
            {this.state.open && <TriangleDown />}
            {!this.state.open && <TriangleRight />}
          </div>
          <div className={propertiesStyle.Title}>Audio clip</div>
        </div>
        {this.state.open && (
          <div>
            <div className={styles.lists}>
              <div
                className={styles.Property}
                onClick={() =>
                  this.props.toggleAudioView((audio) =>
                    this.audioCallback(audio)
                  )
                }
              >
                <div className={styles.Audio}>
                  {this.props?.item?.src
                    ?.split("/")
                    [this.props?.item?.src?.split("/").length - 1]?.replace(
                      /%2F/g,
                      "/"
                    )}
                </div>
              </div>
              <div className={styles.Property}>
                <label className={styles.CheckboxContainer}>
                  Loop
                  <input
                    type="checkbox"
                    checked={this.props.item.loop ? true : false}
                    onChange={(e) => {
                      this.props.item.audio_player.loop =
                        e.currentTarget.checked;
                      this.updateProperty("loop", e.currentTarget.checked);
                    }}
                  />
                  <span className={styles.Checkmark} />
                </label>
              </div>
              <div className={styles.Property}>
                <label
                  className={styles.Play}
                  draggable={true}
                  onDragStart={(event) => this.drag(event, "TRIGGER", "play")}
                >
                  <Play />
                </label>
              </div>
              <div className={styles.Property}>
                <label
                  className={styles.Play}
                  draggable={true}
                  onDragStart={(event) => this.drag(event, "TRIGGER", "stop")}
                >
                  STOP
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
