const _ = require("lodash");

const PIXI = window.PIXI;

exports.setProperty = function (item, property, value) {
  try {
    let variable = property.split(".");

    let obj = item;
    obj.new_width = null;
    obj.new_height = null;

    // for (let i = 0; i < variable.length - 1; i++) {
    //   obj = obj[variable[i]];
    // }
    if (property.indexOf("drop_shadow.enabled") === 0) {
      _.set(item, property, value);
    } else if (property.indexOf("drop_shadow.offset") === 0) {
      item.drop_shadow.offset[variable[2]] = parseInt(value);
      let filter = item.filters.find((f) => f.name === "DROPSHADOW");
      if (filter) {
        filter.offset[variable[2]] = parseInt(value);
      }
    } else if (property.indexOf("drop_shadow.shadowOnly") === 0) {
      _.set(item, property, value);
      let filter = item.filters.find((f) => f.name === "DROPSHADOW");
      if (filter) {
        filter[variable[1]] = value;
      }
    } else if (property.indexOf("drop_shadow") === 0) {
      _.set(item, property, parseFloat(value));
      let filter = item.filters.find((f) => f.name === "DROPSHADOW");
      if (filter) {
        filter[variable[1]] = parseFloat(value);
      }
    } else if (item.type === "GROUP" && property === "alpha") {
      item.alpha = 1;

      if (
        item.filters.length === 0 ||
        !item.filters?.find((f) => f.name === "ALPHA")
      ) {
        let new_filter = new PIXI.filters.AlphaFilter(value);
        new_filter.name = "ALPHA";
        item.filters.push(new_filter);
      } else {
        item.filters.find((f) => f.name === "ALPHA").alpha = value;
      }
    } else {
      try {
        item.codeRunner &&
          item.codeRunner.onBeforeValueChange &&
          item.codeRunner.onBeforeValueChange({
            property: property,
            old_value: _.get(item, property),
            new_value: value,
          });
      } catch (err) {
        console.log(err);
      }
      _.set(item, property, value);
    }

    obj = item;

    if (property === "image") {
      obj._texture = PIXI.Texture.fromImage(value);
    }

    if (item.type === "RECTANGLE" && property === "colour") {
      obj.fillColor = string2hex(value);
    }
    if (item.type === "CIRCLE" && property === "colour") {
      obj.fillColor = string2hex(value);
      _redrawCircle({ item: obj });
    }
    if (obj.type === "IMAGE" && property === "colour") {
      obj.tint = string2hex(value);
    }
    if (obj.type === "TEXT" || obj.type === "CLOCK") {
      if (item.text_transform?.uppercase) {
        item.text = item.text?.toUpperCase();
      }
      if (item.text_transform?.lowercase) {
        item.text = item.text?.toLowerCase();
      }

      let style = obj.style;
      if (property === "rtl" && value) {
        let ctx = item.canvas.getContext("2d");
        ctx.textAlign = "end";
      }
      // if (property.indexOf("style") === 0) {
      //   style = obj;
      // }
      if (property !== "style.letterSpacing") {
        if (property !== "style.fontSize") {
          style.fontSize = style.original_size || style.fontSize;
        }
        try {
          let textMetrics = PIXI.TextMetrics.measureText(item.text, style);
          item.width = parseInt(textMetrics.width);
          item.height = parseInt(textMetrics.height);
        } catch (err) {
          debugger;
        }
        style.original_size = style.fontSize;
        while (
          item.maxHeight > 0 &&
          item.height > item.maxHeight &&
          style.fontSize !== 1
        ) {
          style.fontSize -= 1;
          style.fontSize = Math.max(1, style.fontSize);
          try {
            let textMetrics = PIXI.TextMetrics.measureText(item.text, style);
            item.height = parseInt(textMetrics.height);
            item.width = Math.max(1, parseInt(textMetrics.width));
          } catch (err) {
            debugger;
          }
        }
      } else {
        let textMetrics = PIXI.TextMetrics.measureText(item.text, style);
        item.width = parseInt(textMetrics.width);
        item.height = parseInt(textMetrics.height);
      }
    }

    if (property === "radius" && obj.type === "CIRCLE") {
      _redrawCircle({ item: obj });
    }

    if (property === "width" && obj.type === "RECTANGLE") {
      obj.new_width = parseInt(value);
    }

    if (property === "height" && obj.type === "RECTANGLE") {
      obj.new_height = parseInt(value);
    }

    if (obj.type === "RECTANGLE") {
      obj.fillColor = item.geometry.graphicsData[0].fillStyle.color;
      _redrawRectangle({ item: obj });
    }

    if (obj.maxWidth > 0 && obj.width > obj.maxWidth) {
      obj.width = obj.maxWidth;
    }

    if (item.updateAnchor) {
      obj.updateAnchor();
    }
  } catch (e) {}
};

function string2hex(string) {
  if (typeof string === "string" && string[0] === "#") {
    string = string.substr(1);
  }

  return parseInt(string, 16);
}

function _redrawRectangle(item) {
  item = item?.item || item;

  let width =
    item.new_width === null || item.new_width === undefined
      ? item.width || item._width
      : item.new_width;

  let height =
    item.new_height === null || item.new_height === undefined
      ? item.height || item._height
      : item.new_height;
  let radius = item.radius;
  let width_stf = width;
  let height_stf = height;

  if (item.size_to_fit && item.size_to_fit.length > 0) {
    let x_min = 0;
    let x_max = 0;
    let y_min = 0;
    let y_max = 0;

    item.size_to_fit
      ?.filter((item) => item?.visible)
      ?.map((stf_item, index) => {
        x_min = Math.min(index === 0 ? stf_item.x : x_min, stf_item.x);
        x_max = Math.max(x_max, stf_item.x + stf_item.width);
        y_min = Math.min(index === 0 ? stf_item.y : y_min, stf_item.y);
        y_max = Math.max(y_max, stf_item.y + stf_item.height);
      });
    width = x_max - x_min;
    height = y_max - y_min;

    width_stf = width + (parseInt(item.size_to_fit_padding.width) || 0);
    height_stf = height + (parseInt(item.size_to_fit_padding.height) || 0);
  }

  if (item.gradient && item.gradient.enabled) {
    // console.log(item.gradient);
    let texture = _gradient({
      width: width_stf,
      height: height_stf,
      palette: item.gradient.palette,
      type: item.gradient.type,
      angle: item.gradient.angle,
    });
    item.clear();
    item.beginTextureFill({
      texture: texture,
      // matrix: createGradientBox(width, height, 0, 0, 0),
    });

    item.drawRoundedRect(0, 0, width_stf, height_stf, radius);
    item.endFill();
  } else {
    let color = item.fillColor;

    item.clear();
    item.beginFill(color);
    item.drawRoundedRect(0, 0, width_stf, height_stf, radius);
    item.endFill();
  }
  item.width = width_stf;
  item.height = height_stf;
  item.updateAnchor();
}
exports.redrawRectangle = _redrawRectangle;
function _redrawCircle({ item }) {
  let radius = item.radius;
  if (item.gradient && item.gradient.enabled) {
    let texture = _gradient({
      width: radius * 2,
      height: radius * 2,
      palette: item.gradient.palette,
      type: item.gradient.type,
      angle: item.gradient.angle,
    });

    item.clear();
    // item.beginTextureFill({
    //   texture: texture,
    //   // matrix: createGradientBox(radius * 2, radius * 2, 0, 0, 0),
    // });
    var mtr = new PIXI.Matrix();
    mtr.translate(-radius, -radius);

    item.beginTextureFill({
      texture: texture,
      matrix: mtr,
    });

    item.drawCircle(0, 0, radius);
    item.endFill();
  } else {
    let color = item.fillColor;
    item.clear();
    item.beginFill(color);
    item.drawCircle(0, 0, radius);
    item.endFill();
  }

  item.updateAnchor();
}

exports.redrawCircle = _redrawCircle;

function gradient2(from, to, width, height) {
  const c = document.createElement("canvas");
  c.width = width;
  c.height = height;
  const ctx = c.getContext("2d");
  const grd = ctx.createLinearGradient(0, 0, width, height);
  grd.addColorStop(0, from);
  grd.addColorStop(1, to);
  ctx.fillStyle = grd;
  ctx.fillRect(0, 0, width, height);
  return new PIXI.Texture.from(c);
}

exports.getProperty = function (item, property) {
  let variable = property.split(".");
  let obj = item;
  for (let i = 0; i < variable.length - 1; i++) {
    obj = obj[variable[i]];
  }
  return obj[variable[variable.length - 1]];
};

exports.trigger = function (item, value) {
  try {
    if (value === "play" && item !== undefined && item.type === "VIDEO") {
      if (item._texture) {
        console.log(
          "ready: " + item._texture.baseTexture.resource.source.readyState
        );
        if (item._texture.baseTexture.resource.source.readyState === 4) {
          item._texture.baseTexture.resource.source.currentTime = 0;
          item._texture.baseTexture.resource.source.pause();

          item._texture.baseTexture.update();
          item._texture.baseTexture.resource.source.play()?.then(() => {
            item.visible = true;
          });
        }
      } else if (item !== undefined) {
        // item.element.texture =item.textures[0];
        //  item.gotoAndStop(0);
        // window.startDebug = true;
        // item._texture.baseTexture.update();
        item.visible = true;
      }
    } else if (
      value === "reset" &&
      item !== undefined &&
      item.type === "VIDEO"
    ) {
      item._texture.baseTexture.resource.source.currentTime = 0;
      item._texture.baseTexture.resource.source.pause();
      item._texture.baseTexture.update();
      item.visible = false;
      item.reverse = false;
    } else if (
      value === "forwards" &&
      item !== undefined &&
      item.type === "VIDEO"
    ) {
      item.reverse = false;
    } else if (
      value === "backwards" &&
      item !== undefined &&
      item.type === "VIDEO"
    ) {
      item.reverse = true;
    }
  } catch (e) {
    console.log(e);
  }
};

exports.timeline = function (animation, sceneName, action, once = false) {
  let scene = window.hyperAPI.getSceneByName(sceneName);
  if (scene) {
    let tl = scene.timelines.find((t) => t.name === animation.name);
    if (tl) {
      if (action === "PLAY") {
        tl.play();
      } else if (action === "STOP") {
        tl.stop();
      }
    }
  }
};

exports.script = function ({ kf, delta, scene }) {
  try {
    if (!kf.codeRunner) {
      try {
        // eslint-disable-next-line no-new-func
        let func = new Function(
          "hyperAPI",
          "currentItem",
          `
            let window = '';

            function onUpdateAnim() {

            }
            function onBeforeValueChange() {

            }
            function update() {

            }
            function loaded() {

            }
            function trigger() {

            }
             ${kf.code}
              return {
                trigger: trigger,
                update: update,
                loaded: loaded,
                onUpdateAnim: onUpdateAnim,
                onBeforeValueChange: onBeforeValueChange,
              };
            `
        )(window.hyperAPI, kf);

        kf.codeRunner = func;
      } catch (err) {
        console.log("Error in script", err);
      }
    }
    kf.codeRunner.trigger(scene, delta);
  } catch (e) {
    if (window.editor) {
      console.error(scene.name, "SCRIPT TRIGGER", e);
    }
  }
};

function _gradient({
  palette,
  width = 256,
  height = 256,
  type = "radial",
  angle = 90,
}) {
  const c = document.createElement("canvas");
  c.width = width;
  c.height = height;
  const ctx = c.getContext("2d");
  let grd;
  if (type === "linear") {
    let points = linearGradient_a(width, height, angle);
    grd = ctx.createLinearGradient(points.tx, points.ty, points.bx, points.by);
    ctx.beginPath();

    for (let i = 0; i < palette.length; i++) {
      let p = palette[i];
      grd.addColorStop(parseFloat(p.offset), p.color);
    }
  } else if (type === "radial") {
    grd = ctx.createRadialGradient(
      width / 2,
      height / 2,
      0,
      width / 2,
      height / 2,
      width / 2
    );
    for (let i = 0; i < palette.length; i++) {
      let p = palette[i];
      grd.addColorStop(parseFloat(p.offset), p.color);
    }
  }

  ctx.fillStyle = grd;
  ctx.fillRect(0, 0, width, height);

  return PIXI.Texture.from(c, {
    wrapMode: PIXI.WRAP_MODES.CLAMP,
  });
}
exports.gradient = _gradient;
//Calculate Linear Gradient Angle and Cut Points
function linearGradient_a(w, h, deg) {
  var caseAngle1 = Math.round((Math.atan(w / h) * 180) / Math.PI), //rundung
    caseAngle2 = Math.round(180 - caseAngle1),
    caseAngle3 = Math.round(180 + caseAngle1),
    caseAngle4 = Math.round(360 - caseAngle1);

  let tx;
  let wh;
  var bx = (tx = wh = w / 2);
  let hh = h / 2;
  let ty = h;
  let by = 0;
  let angInRad = (deg * Math.PI) / 180;
  let count1;

  if (deg == caseAngle1) {
    tx = 0;
    bx = w;
  } else if (deg == caseAngle2) {
    tx = 0;
    ty = 0;
    bx = w;
    by = h;
  } else if (deg == caseAngle3) {
    tx = w;
    ty = 0;
    bx = 0;
    by = h;
  } else if (deg == caseAngle4) {
    tx = w;
    ty = h;
    bx = 0;
    by = 0;
  } else {
    var mtan = Math.tan(angInRad);

    if (0 < deg && deg < caseAngle1) {
      count1 = (mtan * h) / 2;
      tx = wh - count1;
      bx = wh + count1;
    } else if (caseAngle1 < deg && deg < caseAngle2) {
      count1 = wh / mtan;
      tx = 0;
      ty = hh + count1;
      bx = w;
      by = hh - count1;
    } else if (caseAngle2 < deg && deg < caseAngle3) {
      count1 = (mtan * h) / 2;
      tx = wh + count1;
      ty = 0;
      bx = wh - count1;
      by = h;
    } else if (caseAngle3 < deg && deg < caseAngle4) {
      count1 = wh / mtan;
      tx = w;
      ty = hh - count1;
      bx = 0;
      by = hh + count1;
    } else if (caseAngle4 < deg && deg < 361) {
      count1 = (mtan * h) / 2;
      tx = wh - count1;
      ty = h;
      bx = wh + count1;
      by = 0;
    }
  }
  return { tx: tx, ty: ty, bx: bx, by: by };
}

function createGradientBox(width, height, rotation, tx, ty) {
  let matrix = new PIXI.Matrix();

  //applying transformations
  matrix.rotate(rotation);
  matrix.scale(width / 256, height / 256);
  matrix.tx = tx + width / 2;
  matrix.ty = ty + width / 2;

  //fixing anchor point of the texture
  var mtr = new PIXI.Matrix();
  mtr.translate(-width / 2, -height / 2);
  mtr.rotate(rotation);
  matrix.translate(mtr.tx, mtr.ty);
  return matrix;
}
